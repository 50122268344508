import { objectToQueryString } from "../../shared/utils";

export function datacloudDataTable(args) {
  const {
    chartName,
    endpoint,
    sortColumn,
    sortDirection = "asc",
    chartIndex,
  } = args;
  let filters = args.filters;
  let timestamp = null;
  let bucket = null;
  let user = null;
  let team = null;

  return {
    showDataTable: false,
    sortColumn,
    sortDirection,
    page: 1,
    dataTable: {},
    initialPageLoaded: false,
    loading: false,

    showAndFetch(eventDetail, newFilters) {
      // Index is used for hard metrics in snapshot results where an unknown
      // number of charts and are shown and all have the same invoking function
      if (chartIndex !== undefined && chartIndex !== eventDetail.chartIndex)
        return;

      filters = newFilters;

      this.showDataTable = true;
      this.page = 1; // if we don't reset page we can end up with a blank page
      if (eventDetail.d) {
        timestamp = Math.floor(new Date(eventDetail.d.date));
      } else {
        timestamp = null;
      }
      bucket = eventDetail.bucket;
      user = eventDetail.user;
      team = eventDetail.team;
      this.fetchRawData();
    },
    fetchRawData() {
      if (this.loading) return;

      this.loading = true;
      // we rely on objectToQueryString to exclude null values
      // for handling either timestamp or bucket
      const filtersQueryString = objectToQueryString({
        ...filters,
        timestamp,
        bucket,
        user,
        team,
        // Use the timestamp exclusively for the range, not the start_date nor end_date
        s: this.sortColumn,
        d: this.sortDirection,
        page: this.page,
      });
      // Snapshot results the endpoint includes the chart name; omit the chartname if it is not passed in
      fetch(
        `${endpoint}${
          chartName ? `/${chartName}` : ""
        }/data_table?${filtersQueryString}`,
      )
        .then((response) => {
          this.loading = false;
          this.initialPageLoaded = true;
          if (response.ok) return response.json();

          alert("Something went wrong. Please reload the page and try again.");
        })
        .then((data) => {
          this.dataTable = data;
        })
        .catch(() => {
          alert(
            "Your connection dropped. Please reload the page and try again.",
          );
        });
    },
    sortData(column) {
      if (this.sortColumn === column) {
        this.sortDirection = this.sortDirection === "asc" ? "desc" : "asc";
      } else {
        this.sortColumn = column;
        this.sortDirection = "asc";
      }
      this.fetchRawData();
    },
    changePage(page) {
      this.page = page;
      this.fetchRawData();
    },
    range(start, end) {
      // add support for 1 based arrays for easier pagination
      return Array(end - start + 1)
        .fill()
        .map((_, idx) => start + idx);
    },
    // this copies the default kaminari pagination logic
    relevantPages(
      totalPages,
      currentPage,
      windowSize = 4,
      left = 0,
      right = 0,
    ) {
      const leftWindowPlusOne = this.range(1, left); // fixed here
      const rightWindowPlusOne = this.range(totalPages - right + 1, totalPages);
      const insideWindowPlusEachSides = this.range(
        currentPage - windowSize - 1,
        currentPage + windowSize + 1,
      );

      return [
        ...new Set([
          ...leftWindowPlusOne,
          ...insideWindowPlusEachSides,
          ...rightWindowPlusOne,
        ]),
      ]
        .sort((a, b) => a - b)
        .filter((x) => x >= 1 && x <= totalPages);
    },
  };
}
