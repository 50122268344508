export function emailConfirmation() {
  return {
    handlePaste(event) {
      const { form } = this.$refs;

      setTimeout(() => {
        if (event.target.value.length === 6) {
          form.submit();
        }
      }, 50);
    },
  };
}
