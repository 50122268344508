import { objectToQueryString } from "../../shared/utils";

export function unlinkedUsers(args) {
  const { dataFetchEndpoint } = args;

  return {
    showDataTable: false,
    page: 1,
    labels: {},
    pagination: {},
    unlinkedDxUsers: [],
    unlinkedDxdcUsers: [],
    initialPageLoaded: false,
    loading: false,
    selectedUserLinks: {},
    errorMessage: "",

    init() {
      const scrollableDiv = document.querySelector("#scrollableDiv");
      if (scrollableDiv) {
        scrollableDiv.addEventListener("scroll", () => {
          if (
            scrollableDiv.scrollHeight - scrollableDiv.scrollTop ===
              scrollableDiv.clientHeight &&
            this.pagination.page < this.pagination.totalPages
          ) {
            this.page++;
            this.fetchRawData();
          }
        });
      }
    },
    showAndFetch() {
      this.showDataTable = true;
      this.page = 1; // if we don't reset page we can end up with a blank page
      this.unlinkedDxUsers = [];
      this.initialPageLoaded = false;
      this.fetchRawData();
    },
    fetchRawData() {
      if (this.loading) return;

      this.loading = true;

      // we rely on objectToQueryString to exclude null values
      const filtersQueryString = objectToQueryString({
        page: this.page,
      });
      fetch(`${dataFetchEndpoint}?${filtersQueryString}`)
        .then((response) => {
          this.loading = false;
          this.initialPageLoaded = true;
          if (response.ok) return response.json();

          alert("Something went wrong. Please reload the page and try again.");
        })
        .then((data) => {
          this.pagination = data.pagination;
          if (this.pagination.page == 1) {
            this.unlinkedDxUsers = data.unlinked_dx_users;
          } else {
            this.unlinkedDxUsers = [
              ...this.unlinkedDxUsers,
              ...data.unlinked_dx_users,
            ];
          }
          this.unlinkedDxdcUsers = data.unlinked_dxdc_users;
          this.labels = data.labels;
        })
        .catch(() => {
          alert(
            "Your connection dropped. Please reload the page and try again.",
          );
        });
    },
    handleUserSelection(dxUserId, dxdcUserId) {
      if (dxdcUserId) {
        for (const [key, value] of Object.entries(this.selectedUserLinks)) {
          if (value === dxdcUserId && key !== dxUserId) {
            this.errorMessage = `Error: ${this.labels["linking_attribute"]} already assigned to a different user.`;
            return;
          }
        }
        this.selectedUserLinks[dxUserId] = dxdcUserId;
      } else {
        delete this.selectedUserLinks[dxUserId];
      }
      this.errorMessage = null;
    },
    formValid() {
      return (
        Object.keys(this.selectedUserLinks).length > 0 &&
        this.errorMessage === null
      );
    },
  };
}
