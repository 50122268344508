export function snapshotIntakeInternalTools(args) {
  const { internalToolResponses, formLength } = args;

  return {
    internalToolResponses,
    focusedInternalToolId: null,
    init() {
      this.focusNextRow();

      this.$watch("internalToolResponses", () => this.focusNextRow());
    },
    focusNextRow() {
      this.$nextTick(() => {
        const nextRow = document.querySelector(".x-unfilled");

        if (nextRow)
          this.focusedInternalToolId = parseInt(nextRow.dataset.tool);
      });
    },
    formValid() {
      return Object.values(this.internalToolResponses).length >= formLength;
    },
    isFilled(internalToolId) {
      return this.internalToolResponses[internalToolId];
    },
    isFilledOrFocused(internalToolId) {
      return (
        this.isFilled(internalToolId) ||
        internalToolId == this.focusedInternalToolId
      );
    },
  };
}
