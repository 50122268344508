import Sortable from "sortablejs";

export function snapshotSetupInternalTools(args) {
  const { internalTools } = args;

  return {
    internalTools,
    init() {
      const form = this.$root.closest("form");

      if (form) {
        const internalToolsEvent = new CustomEvent("dx:input-init", {
          detail: {
            name: "internalTools",
            values: this.internalTools
              .filter((internalTool) => internalTool.is_active)
              .map((internalTool) => ({
                id: internalTool.id,
                order_on_page: internalTool.order_on_page,
              })),
          },
        });
        form.dispatchEvent(internalToolsEvent);

        this.$watch("internalTools", () => this.publishValues(form));
      }
    },
    activeInternalTools() {
      return this.internalTools.filter((internalTool) =>
        this.isActive(internalTool.id),
      );
    },
    publishValues(form) {
      const internalToolEvent = new CustomEvent("dx:input-changed", {
        detail: {
          name: "internalTools",
          values: this.internalTools
            .filter((internalTool) => internalTool.is_active)
            .map((internalTool) => ({
              id: internalTool.id,
              order_on_page: internalTool.order_on_page,
            })),
        },
      });

      form.dispatchEvent(internalToolEvent);
    },
    isActive(internalToolId) {
      return this.internalTools.find(
        (internalTool) =>
          internalTool.id === internalToolId && internalTool.is_active,
      );
    },
    toggleTool(internalToolId) {
      if (this.isActive(internalToolId)) {
        this.deselectTool(internalToolId);
        return;
      }

      this.selectTool(internalToolId);
    },
    selectTool(internalToolId) {
      const internalTool = this.internalTools.find(
        (internalTool) => internalTool.id === internalToolId,
      );
      internalTool.is_active = true;
    },
    deselectTool(internalToolId) {
      const internalTool = this.internalTools.find(
        (internalTool) => internalTool.id === internalToolId,
      );
      internalTool.is_active = false;
    },
    setupToolsSortable() {
      const self = this;
      const sortableCsatList = document.getElementById(
        "sortable-internal-tool-items",
      );
      new Sortable(sortableCsatList, {
        animation: 150,
        handle: ".sortable-handle",
        onEnd: function () {
          const newOrder = Array.from(
            self.$refs.sortable.querySelectorAll(".sortable-internal-item"),
          ).map((item, index) => {
            return {
              name: item.innerText,
              id: item?.dataset?.itemId,
              order_on_page: index,
            };
          });

          const map = new Map();
          self.internalTools.forEach((item) =>
            map.set(item?.id.toString(), item),
          );
          const newArray = newOrder.map((item) => ({
            ...map.get(item?.id),
            order_on_page: item.order_on_page,
          }));

          self.internalTools = newArray;
        },
      });
    },
  };
}
