////
// Keep this class consistent with Avatar::UserComponent.rb

import { initialsAvatar } from "./initialsAvatar";

// to ensure consistent FE/BE-rendering of user avatars
export function userAvatar(user, options = {}) {
  const rounded = options.square
    ? "rounded-none"
    : options.roundedLeft
      ? "rounded-l"
      : "rounded-full";

  const css_class = `${
    options.class || "h-7 w-7"
  } ${rounded} overflow-hidden shrink-0`;

  let styles = "";

  if (css_class.indexOf("border-white") >= 0) {
    styles += "border-color: white;";
  }

  if (user.avatar) {
    return `<img loading="lazy" title="${user.name}" src="${user.avatar}" class="${css_class}" style="${styles}" />`;
  }

  return initialsAvatar(user, css_class);
}
