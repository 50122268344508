import { postData } from "../../../shared/utils";

export function frontlineGroupSetup({ creator, notificationsSupported }) {
  return {
    step: "info",
    steps: ["info"], // Gets determined dynamically
    name: "",
    members: [creator],
    unlinkedUsers: [],
    alerts: {
      staleness: 7,
      review: 2,
      size: 600,
    },
    defaultAlertLevel: "individual",
    terms: "wip, do not merge",
    labels: "",
    neededApprovalsOn: false,
    neededApprovals: 1,
    ignoreWeekends: true,
    init() {
      this.buildSteps();

      this.$nextTick(() => {
        document.getElementById("name").focus();
      });
    },
    stepIsActive(s) {
      const currentStepIdx = this.steps.indexOf(this.step);
      return this.steps.indexOf(s) <= currentStepIdx;
    },
    isFirstStep() {
      return this.steps[0] === this.step;
    },
    isLastStep() {
      const currentStepIdx = this.steps.indexOf(this.step);
      return currentStepIdx === this.steps.length - 1;
    },
    goToPreviousStep() {
      const currentStepIdx = this.steps.indexOf(this.step);
      this.step = this.steps[currentStepIdx - 1];
    },
    goToNextStep() {
      const currentStepIdx = this.steps.indexOf(this.step);
      this.step = this.steps[currentStepIdx + 1];
    },
    buildSteps() {
      this.steps = ["info"];
      if (this.unlinkedUsers.length > 0) this.steps.push("github_usernames");
      this.steps.push("alerts");
      if (notificationsSupported) this.steps.push("notifications");
    },
    handleStep() {
      if (this.step === "info") {
        this.processInfoStep();
      } else {
        if (this.isLastStep()) {
          this.finish();
        } else {
          this.goToNextStep();
        }
      }
    },
    get isFormValid() {
      if (this.step === "info") {
        return !!this.name.trim().length && !!this.members.length;
      }

      if (this.step === "github_usernames") {
        return (
          this.unlinkedUsers.filter((u) => !u.github_username?.trim()?.length)
            .length === 0
        );
      }

      if (this.step === "alerts") {
        const validThresholds = Object.values(this.alerts).filter(
          (threshold) => !!threshold && parseInt(threshold) >= 0,
        );
        return validThresholds.length === Object.values(this.alerts).length;
      }

      return true;
    },
    processInfoStep() {
      fetch(
        `/frontline/setup/unlinked_users?ids=${this.members
          .map((c) => c.id)
          .join(",")}`,
      )
        .then((response) => response.json())
        .then((data) => {
          this.unlinkedUsers = data;
          this.buildSteps();
          this.goToNextStep();
        });
    },
    finish() {
      const params = {
        name: this.name,
        member_ids: this.members.map((c) => c.id),
        staleness_threshold: this.alerts.staleness,
        review_turnaround_threshold: this.alerts.review,
        size_threshold: this.alerts.size,
        ignore_terms: this.terms,
        ignore_labels: this.labels,
        default_alert_level: this.defaultAlertLevel,
        usernames: this.unlinkedUsers,
        needed_approvals: this.neededApprovalsOn ? this.neededApprovals : null,
        ignore_weekends: this.ignoreWeekends,
      };

      postData(`/frontline/groups`, params).then(
        (resp) => (window.location.href = resp.path),
      );
    },
  };
}
