import { StackedBarChart } from "@get-dx/d3-charts";
import tippy from "tippy.js";
import dayjs from "dayjs";
import {
  objectToQueryString,
  setQueryStringParam,
  setLocalStorageItem,
  getLocalStorageItem,
  indexBy,
} from "../../shared/utils";

const _ALLOC_COLORS = [
  [52, 211, 153], // Emerald 400
  [96, 165, 250], // Blue 400
  [248, 113, 113], // Red 400
  [251, 146, 60], // Orange 400
  [74, 222, 128], // Green 400
  [129, 140, 248], // Indigo 400
  [251, 113, 133], // Rose 400
  [251, 191, 36], // Amber 400
  [163, 163, 163], // Neutral 400
  [45, 212, 191], // Teal 400
  [161, 161, 170], // Zinc 400
  [167, 139, 250], // Violet 400
  [250, 204, 21], // Yellow 400
  [244, 114, 182], // Pink 400
  [34, 211, 238], // Cyan 400
  [192, 132, 252], // Purple 400
  [163, 230, 53], // Lime 400
  [156, 163, 175], // Gray 400
  [56, 189, 248], // Sky 400
  [232, 121, 249], // Fuchsia 400
  [148, 163, 184], // Slate 400
];

const _ALLOC_UNCATEGORIZED = "Other";

const _ALLOC_COLOR_UNCATEGORIZED = [75, 85, 99];

export function datacloudAllocationReport(args) {
  const {
    endpoint,
    issuesEndpoint,
    settingsEndpoint,
    barChartElId,
    range,
    teams,
    interval,
    categorization,
    csvPath,
  } = args;

  return {
    range,
    teams,
    interval,
    loading: true,
    initialLoad: true,
    data: {},
    valuesMap: {},
    colors: {},
    chart: {
      obj: null,
      series: [],
      truncated: 0,
    },
    table: {
      sortDir: "asc",
      sort: "category",
      truncated: false,
    },
    issuesModal: {
      title: "",
      open: false,
      loading: false,
      initialPageLoaded: false,
      date: undefined,
      category: undefined,
      data: {},
    },
    settingsModal: {
      open: false,
      loaded: false,
      data: {},
    },
    capexModal: {
      open: false,
      interval: getLocalStorageItem("capexModal.interval", "monthly"),
      lookback: getLocalStorageItem("capexModal.lookback", "12"),
      initialCapexTypes: JSON.parse(
        getLocalStorageItem("capexModal.capexTypes", "[]"),
      ),
      data: {},
    },
    categorization,
    init() {
      this.fetchData();

      this.$watch("capexModal.interval", (val) => {
        setLocalStorageItem("capexModal.interval", val);
      });

      this.$watch("capexModal.lookback", (val) => {
        setLocalStorageItem("capexModal.lookback", val);
        document.dispatchEvent(new CustomEvent(`dx:capex-load-options`, {}));
      });
    },
    get hasData() {
      return Object.keys(this.data.breakdowns || {}).length > 0;
    },
    get csvPathWithParams() {
      const queryStr = objectToQueryString({
        categorization: this.categorization,
        r: this.range,
        sq: this.teams,
        interval: this.interval,
      });

      return `${csvPath}?${queryStr}`;
    },
    setRange(range) {
      const changed = this.range !== range;

      setQueryStringParam("r", range);
      this.range = range;

      if (changed) {
        this.fetchData(this.drawBarChart.bind(this));
      }
    },
    setTeams(ids) {
      this.teams = ids;
      setQueryStringParam("sq", ids);
      this.fetchData(this.drawBarChart.bind(this));
    },
    setCategorization(categorization) {
      if (categorization === this.categorization) return;
      this.categorization = categorization;
      setQueryStringParam("categorization", categorization);
      this.fetchData(this.drawBarChart.bind(this));
    },
    setInterval(interval) {
      if (interval === this.interval) return;
      this.interval = interval;
      setQueryStringParam("interval", interval);
      this.fetchData(this.drawBarChart.bind(this));
    },
    fetchData(callback) {
      this.loading = true;

      const queryStr = objectToQueryString({
        categorization: this.categorization,
        r: this.range,
        sq: this.teams,
        interval: this.interval,
      });

      fetch(`${endpoint}?${queryStr}`)
        .then((response) => response.json())
        .then((resp) => {
          this.data = resp;

          for (const date in this.data.breakdowns) {
            this.valuesMap[date] = indexBy(
              this.data.breakdowns[date],
              "category",
            );
          }

          this.loading = false;
          this.initialLoad = false;
          if (callback) callback();
        });
    },
    formatDate(d) {
      if (this.interval === "quarterly") {
        const dayjsObj = dayjs(d);
        return `Q${dayjsObj.quarter()} ${dayjsObj.year()}`;
      }

      return dayjs(d).format("MMM YYYY");
    },
    formatValue(n) {
      return parseFloat(parseFloat(n).toFixed(1));
    },
    valueForCategoryAndDate(category, date) {
      return this.valuesMap[date]?.[category]?.pct || 0;
    },
    drawBarChart() {
      const el = document.getElementById(barChartElId);
      if (!el) return;

      el.innerHTML = "";

      const { categories, breakdowns, total_categories } = this.data;

      let values = [];
      let series = [];

      // Only display 20 categories, because the chart is useless when there are a ton of tiny items
      let displayedCategories = Object.entries(categories).slice(0, 21);
      if (["epic", "initiative"].includes(this.categorization)) {
        // Don't show the _ALLOC_UNCATEGORIZED category in the chart when viewing by Epic/Initiative. But, we still want it in the table.
        displayedCategories = displayedCategories.filter(
          (c) => c[1] !== _ALLOC_UNCATEGORIZED,
        );
      }
      displayedCategories = displayedCategories.slice(0, 20);
      this.chart.truncated = total_categories - displayedCategories.length;

      for (const [idx, category] of displayedCategories) {
        let color;
        if (category === _ALLOC_UNCATEGORIZED) {
          color = _ALLOC_COLOR_UNCATEGORIZED;
        } else {
          color = _ALLOC_COLORS[idx % _ALLOC_COLORS.length];
        }

        this.colors[category] = color;

        series.push({
          key: category,
          color: `rgb(${this.colors[category].join(",")})`,
        });
      }

      this.colors[_ALLOC_UNCATEGORIZED] = _ALLOC_COLOR_UNCATEGORIZED;

      for (const time of Object.keys(breakdowns)) {
        for (const [_, s] of Object.entries(series)) {
          const val = this.valueForCategoryAndDate(s.key, time);

          values.push({
            xValue: time,
            yValue: val,
            series: s.key,
          });
        }
      }

      const chart = new StackedBarChart({
        elChart: el,
        values,
        series,
        xAxisTickLabelFormat: this.formatDate.bind(this),
        axis: {
          x: { label: "" },
          y: { label: "", max: this.chart.truncated > 0 ? undefined : 100 },
        },
        tooltipHtml: (d) => `
          <div class='bg-white rounded-md shadow border text-tiny px-2 py-1.5'>
            <div class='flex border-b pb-1.5 mb-1'>
              <div class='grow font-medium'>${this.formatDate(d.xValue)}</div>
            </div>
            <div class='flex items-center text-gray-700 text-tiny py-1.5'>
              <div class='grow'>${d.series}</div>
              <div class='ml-12'>${this.formatValue(d.yValue)}%</div>
            </div>
          </div>
        `,
      });

      chart.redraw();

      this.chart.series = series;
      this.chart.obj = chart;
    },
    sortableHeaderHtml(column) {
      const isCurrentSort = column === this.table.sort;
      const isAsc = this.table.sortDir === "asc";

      return `
        <div class="text-gray-300 flex flex-col -space-y-1.5 text-xs -mb-px">
          <i class="-mb-px bx bx-caret-up" :class="{'text-gray-500': ${
            isCurrentSort && isAsc
          }}"></i>
          <i class="bx bx-caret-down" :class="{'text-gray-500': ${
            isCurrentSort && !isAsc
          }}"></i>
        </div>
      `;
    },
    tooltipIfTruncated(e, fullText) {
      const el = e.target;

      if (el && el.offsetWidth < el.scrollWidth) {
        tippy(el, {
          content: fullText,
          delay: [250, 0],
        });
      }
    },
    tableOrder() {
      const { categories, total_categories } = this.data;

      // Don't show more than 150 categories, because the table struggles to render when there are a ton of tiny items and
      // it's an overwhelming amount of data. Users can download the CSV to get all data.
      let list = categories.slice(0, 150);
      this.table.truncated = total_categories > list.length;

      if (this.table.sort === "category") {
        list = list.sort();
      } else {
        list = list.sort(
          (a, b) =>
            this.valueForCategoryAndDate(a, this.table.sort) -
            this.valueForCategoryAndDate(b, this.table.sort),
        );
      }

      if (this.table.sortDir === "desc") list = list.reverse();

      return list;
    },
    changeTableSort(column, defaultDir = "desc") {
      if (column !== this.table.sort) {
        this.table.sort = column;
        this.table.sortDir = defaultDir;
      } else {
        this.table.sortDir = this.table.sortDir === "asc" ? "desc" : "asc";
      }
    },
    openIssuesModal(category, date) {
      this.issuesModal.loading = true;
      this.issuesModal.initialPageLoaded = false;
      this.issuesModal.category = category;
      this.issuesModal.date = date;
      this.issuesModal.data = {};
      this.issuesModal.open = true;

      const queryStr = objectToQueryString({
        categorization: this.categorization,
        category,
        date,
        interval: this.interval,
        sq: this.teams,
        per_page: 50,
      });

      fetch(`${issuesEndpoint}?${queryStr}`)
        .then((response) => response.json())
        .then((resp) => {
          this.issuesModal.data = resp;
          this.issuesModal.title = `${category} - ${this.formatDate(date)}`;
          this.issuesModal.loading = false;
          this.issuesModal.initialPageLoaded = true;
        });
    },
    changeIssuesModalPage(page) {
      this.issuesModal.loading = true;

      const queryStr = objectToQueryString({
        categorization: this.categorization,
        category: this.issuesModal.category,
        date: this.issuesModal.date,
        interval: this.interval,
        sq: this.teams,
        per_page: 50,
        page,
      });

      fetch(`${issuesEndpoint}?${queryStr}`)
        .then((response) => response.json())
        .then((resp) => {
          this.issuesModal.data = resp;
          this.issuesModal.loading = false;
        });
    },
    openSettings() {
      this.settingsModal.open = true;

      fetch(settingsEndpoint)
        .then((response) => response.json())
        .then((resp) => {
          if (resp.work_type === "custom_field") {
            this.settingsModal.data = {
              workType: "custom_field",
              field: { id: resp.work_type_value, name: resp.work_type_display },
            };
          } else {
            this.settingsModal.data = { workType: "issue_type" };
          }

          this.settingsModal.loaded = true;
        });
    },
    get settingsFormValid() {
      const { workType, field } = this.settingsModal.data;
      return workType === "issue_type" || !!field?.id;
    },
    openCapexModal() {
      this.capexModal.open = true;
      this.$nextTick(() => {
        document.dispatchEvent(new CustomEvent(`dx:capex-load-options`, {}));
      });
    },
    onCapexOptionsChange(_, val) {
      setLocalStorageItem("capexModal.capexTypes", JSON.stringify(val));
    },
  };
}
