import {
  objectToQueryString,
  setQueryStringParam,
  getQueryStringParams,
  deleteQueryStringParam,
} from "../../shared/utils";

export function triageResultsModal({
  triageResultsUrl,
  teamBreakdownUrl,
  triageImprovementsUrl,
  exportUrl,
}) {
  return {
    open: false,
    initialLoad: false,
    showImprovementsTab: false,
    squadIds: [],
    branchId: null,
    tab: null,
    responses: {
      loaded: false,
      data: [],
      numSquads: 0,
      breakdownData: {},
      expandedDriver: {},
      expandedOpt: "escalation",
      breakdownLoaded: false,
      sort: "escalation",
      sortDir: "desc",
      lastUpdatedFilter: null,
    },
    improvements: {
      loaded: false,
      data: {},
      sort: "squad",
      sortDir: "asc",
      driverFilter: "",
    },
    init() {
      const queryParams = getQueryStringParams();
      const tabFromParams = queryParams.get("triage_tab"); // This is also used in app/controllers/kaizen/latest_snapshot_controller.rb
      const updateFromParams = queryParams.get("triage_updated");
      const branchFromParams = queryParams.get("triage_branch");
      const squadIdsFromParams = queryParams
        .get("triage_teams")
        ?.split(",")
        ?.filter((id) => !!id)
        ?.map((t) => parseInt(t));

      if (squadIdsFromParams?.length) this.squadIds = squadIdsFromParams;
      if (branchFromParams?.length) this.branchId = branchFromParams;
      if (updateFromParams?.length)
        this.responses.lastUpdatedFilter = updateFromParams;

      if (tabFromParams == "responses") {
        this.openModal();
      }

      if (tabFromParams == "improvements") {
        this.openModal();
        this.setTab("improvements");
      }
    },
    openModal() {
      this.open = true;
      this.setTab("responses");
      this.updateQueryParams();
    },
    updateQueryParams() {
      setQueryStringParam("triage_tab", this.tab);
      setQueryStringParam("triage_teams", this.squadIds?.join(",") || null);
      setQueryStringParam("triage_updated", this.responses.lastUpdatedFilter);
      setQueryStringParam("triage_branch", this.branchId);
    },
    clearTriageQueryParams() {
      deleteQueryStringParam("triage_tab");
      deleteQueryStringParam("triage_teams");
      deleteQueryStringParam("triage_updated");
      deleteQueryStringParam("triage_branch");
    },
    setTab(tab) {
      if (this.tab === tab) return;

      this.tab = tab;
      this.updateQueryParams();

      if (this.tab == "improvements" && !this.improvements.loaded) {
        this.fetchImprovementsData();
      }

      if (this.tab == "responses" && !this.responses.loaded) {
        this.fetchSelectionsData();
      }
    },
    refetch() {
      this.improvements.loaded = false;
      this.responses.loaded = false;

      if (this.tab == "improvements") {
        this.fetchImprovementsData();
      } else {
        this.fetchSelectionsData();
      }
    },
    changeSquadIds(squadIds) {
      this.squadIds = squadIds;
      this.updateQueryParams();
      this.refetch();
    },
    changeBranchId(branchId) {
      this.branchId = branchId;
      this.updateQueryParams();
      this.refetch();
    },
    get params() {
      let paramObj = {
        squad_ids: `${this.squadIds.join(",")}`,
        triage_branch: this.branchId,
      };

      if (this.responses.lastUpdatedFilter && this.tab === "responses") {
        paramObj.last_updated_snapshot_id = this.responses.lastUpdatedFilter;
      }

      return `?${objectToQueryString(paramObj)}`;
    },
    get exportUrl() {
      return `${exportUrl}${this.params}`;
    },

    // Stuff for "Results" tab
    fetchSelectionsData() {
      fetch(`${triageResultsUrl}${this.params}`)
        .then((response) => response.json())
        .then((resp) => {
          this.responses.data = resp.results_by_driver;
          this.responses.numSquads = resp.num_squads;
          this.showImprovementsTab = resp.has_improvements_data;
          this.responses.loaded = true;
          this.initialLoad = true;

          if (resp.results_by_driver.length)
            this.expandDriver(this.sortedSelectionData[0]);
        });
    },
    expandDriver(driver) {
      this.responses.breakdownLoaded = false;
      this.responses.expandedDriver = driver;

      fetch(
        `${teamBreakdownUrl}${this.params}&factor_id=${this.responses.expandedDriver.id}`,
      )
        .then((response) => response.json())
        .then((resp) => {
          this.responses.breakdownData = resp;
          this.responses.breakdownLoaded = true;
        });
    },
    get sortedSelectionData() {
      let list = this.responses.data;

      const { sort, sortDir } = this.responses;

      if (sort == "name") {
        list = list.sort((a, b) => a.name.localeCompare(b.name));
      } else {
        list = list.sort((a, b) => {
          const pctA = this.driverPercentageForSelection(a, sort);
          const pctB = this.driverPercentageForSelection(b, sort);

          if (pctA == pctB) return a.name.localeCompare(b.name);
          return pctA - pctB;
        });
      }

      if (sortDir === "desc") list = list.reverse();

      return list;
    },
    driverPercentageForSelection(r, opt) {
      const rounded = (
        (100.0 * r[opt]) /
        (r.escalation + r.improvement_area + r.noop)
      ).toFixed(1);

      return parseFloat(rounded);
    },
    optIsExpanded(opt) {
      return this.responses.expandedOpt == opt;
    },
    toggleOptExpanded(opt) {
      if (this.optIsExpanded(opt)) {
        this.responses.expandedOpt = "";
        return;
      }

      this.responses.expandedOpt = opt;
    },
    changeResponsesSort(col) {
      const { sort, sortDir } = this.responses;

      if (sort == col) {
        this.responses.sortDir = sortDir == "desc" ? "asc" : "desc";
        return;
      }

      this.responses.sort = col;
      this.responses.sortDir = "desc";
    },
    changeLastUpdatedFilter(snapshotId) {
      this.responses.lastUpdatedFilter = snapshotId;
      this.updateQueryParams();
      this.refetch();
    },

    // Stuff for "Improvements" tab
    fetchImprovementsData() {
      fetch(`${triageImprovementsUrl}${this.params}`)
        .then((response) => response.json())
        .then((resp) => {
          this.improvements.data = resp;
          this.improvements.loaded = true;
        });
    },
    get improvementAreaSuccessRate() {
      const { improvements, num_improvement_areas } = this.improvements.data;
      const denominator = Math.max(num_improvement_areas, 1);

      return Math.round((improvements.length / denominator) * 100);
    },
    get sortedImprovements() {
      let list = this.improvements.data.improvements;

      if (this.improvements.driverFilter) {
        list = list.filter((i) => i.factor === this.improvements.driverFilter);
      }

      if (this.improvements.sort === "squad")
        list = list.sort((a, b) => a.squad.localeCompare(b.squad));
      if (this.improvements.sort === "squad_manager")
        list = list.sort((a, b) =>
          a.manager.name.localeCompare(b.manager.name),
        );
      if (this.improvements.sort === "factor")
        list = list.sort((a, b) => a.factor.localeCompare(b.factor));
      if (this.improvements.sort === "sentiment")
        list = list.sort((a, b) => a.sentiment_change - b.sentiment_change);
      if (this.improvements.sort === "priority")
        list = list.sort((a, b) => -a.priority_change - -b.priority_change);

      if (this.improvements.sortDir === "desc") list = list.reverse();

      return list;
    },
    changeImprovementsSort(col) {
      const { sort, sortDir } = this.improvements;

      if (sort == col) {
        this.improvements.sortDir = sortDir == "desc" ? "asc" : "desc";
        return;
      }

      this.improvements.sort = col;
      this.improvements.sortDir = "desc";
    },
    onChangeDriverFilter(e) {
      this.improvements.driverFilter = e.target.value;
    },
  };
}
