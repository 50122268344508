export const chartTooltipHtml = {
  keyDrivers(item) {
    if (item.scale_points == 5) {
      return `
        <div class="flex p-2 text-sm bg-white text-left shadow-lg rounded-lg border">
          <div class="w-64 pr-5 text-xs bg-white text-left border-r border-gray-100">
            <div class="mb-1.5 text-gray-400">
              Survey item
            </div>
            <div class="mt-1 text-gray-700">
              ${item.prompt}
            </div>
            <div class="mt-5 text-gray-400">
              Scoring method
            </div>
            <div class="mt-1 text-gray-700">
              Percent favorable
            </div>
          </div>
          <div class="w-64 ml-5">
            <div class="text-xs text-gray-500 mb-2.5">
              Distribution
            </div>
            <div class="h-5 flex items-center justify-between">
              <div class="w-4/12 text-right pr-2.5 text-xs text-gray-600">Never</div>
              <div class="flex items-center grow w-8/12 rounded-full overflow-hidden">
                <div class="h-1 py-px bg-gray-400 rounded-full" style="width: ${percentageOfResponses(
                  item,
                  "one_count",
                  "count",
                )}"></div>
                <div class="w-8 ml-2 text-gray-500 text-3xs">${percentageOfResponses(
                  item,
                  "one_count",
                  "count",
                )}</div>
              </div>
            </div>
            <div class="h-5 flex items-center justify-between">
              <div class="w-4/12 text-right pr-2.5 text-xs text-gray-600">Rarely</div>
              <div class="flex items-center grow w-8/12 rounded-full overflow-hidden">
                <div class="h-1 py-px bg-gray-400 rounded-full" style="width: ${percentageOfResponses(
                  item,
                  "two_count",
                  "count",
                )}"></div>
                <div class="w-8 ml-2 text-gray-500 text-3xs">${percentageOfResponses(
                  item,
                  "two_count",
                  "count",
                )}</div>
              </div>
            </div>
            <div class="h-5 flex items-center justify-between">
              <div class="w-4/12 text-right pr-2.5 text-xs text-gray-600">Sometimes</div>
              <div class="flex items-center grow w-8/12 rounded-full overflow-hidden">
                <div class="h-1 py-px bg-gray-400 rounded-full" style="width: ${percentageOfResponses(
                  item,
                  "three_count",
                  "count",
                )}"></div>
                <div class="w-8 ml-2 text-gray-500 text-3xs">${percentageOfResponses(
                  item,
                  "three_count",
                  "count",
                )}</div>
              </div>
            </div>
            <div class="h-5 flex items-center justify-between">
              <div class="w-4/12 text-right pr-2.5 text-xs text-gray-600">Very often</div>
              <div class="flex items-center grow w-8/12 rounded-full overflow-hidden">
                <div class="h-1 py-px bg-gray-400 rounded-full" style="width: ${percentageOfResponses(
                  item,
                  "four_count",
                  "count",
                )}"></div>
                <div class="w-8 ml-2 text-gray-500 text-3xs">${percentageOfResponses(
                  item,
                  "four_count",
                  "count",
                )}</div>
              </div>
            </div>
            <div class="h-5 flex items-center justify-between">
              <div class="w-4/12 text-right pr-2.5 text-xs text-gray-600">Always</div>
              <div class="flex items-center grow w-8/12 rounded-full overflow-hidden">
                <div class="h-1 py-px bg-gray-400 rounded-full" style="width: ${percentageOfResponses(
                  item,
                  "five_count",
                  "count",
                )}"></div>
                <div class="w-8 ml-2 text-gray-500 text-3xs">${percentageOfResponses(
                  item,
                  "five_count",
                  "count",
                )}</div>
              </div>
            </div>
          </div>
        </div>
      `;
    }

    return `
      <div class="flex p-2 text-sm bg-white text-left shadow-lg rounded-lg border">
        <div class="w-64 pr-5 text-xs bg-white text-left border-r border-gray-100">
          <div class="mb-1.5 text-gray-400">
            Survey item
          </div>
          <div class="mt-1 text-gray-700">
            Please rate the following area of your work: ${item.prompt}
          </div>
          <div class="mt-5 text-gray-400">
            Scoring method
          </div>
          <div class="mt-1 text-gray-700">
            Percent favorable
          </div>
        </div>
        <div class="w-64 ml-5">
          <div class="text-xs text-gray-500 mb-2.5">
            Distribution
          </div>
          <div class="h-5 flex items-center justify-between">
            <div class="w-4/12 text-right pr-2.5 text-xs text-gray-600">Bad</div>
            <div class="flex items-center grow w-8/12 rounded-full overflow-hidden">
              <div class="h-1 py-px bg-gray-400 rounded-full" style="width: ${percentageOfResponses(
                item,
                "one_count",
                "count",
              )}"></div>
              <div class="w-8 ml-2 text-gray-500 text-3xs">${percentageOfResponses(
                item,
                "one_count",
                "count",
              )}</div>
            </div>
          </div>
          <div class="h-5 flex items-center justify-between">
            <div class="w-4/12 text-right pr-2.5 text-xs text-gray-600">So-so</div>
            <div class="flex items-center grow w-8/12 rounded-full overflow-hidden">
              <div class="h-1 py-px bg-gray-400 rounded-full" style="width: ${percentageOfResponses(
                item,
                "two_count",
                "count",
              )}"></div>
              <div class="w-8 ml-2 text-gray-500 text-3xs">${percentageOfResponses(
                item,
                "two_count",
                "count",
              )}</div>
            </div>
          </div>
          <div class="h-5 flex items-center justify-between">
            <div class="w-4/12 text-right pr-2.5 text-xs text-gray-600">Good</div>
            <div class="flex items-center grow w-8/12 rounded-full overflow-hidden">
              <div class="h-1 py-px bg-gray-400 rounded-full" style="width: ${percentageOfResponses(
                item,
                "three_count",
                "count",
              )}"></div>
              <div class="w-8 ml-2 text-gray-500 text-3xs">${percentageOfResponses(
                item,
                "three_count",
                "count",
              )}</div>
            </div>
          </div>
        </div>
      </div>
    `;
  },
  default(args) {
    const { heading, d, cd, rows, hidePercentChange } = args;

    return `
      <div class='bg-white rounded-md shadow border text-tiny px-2 py-1.5'>
        <div class='flex border-b pb-1.5 mb-1'>
          <div class='grow font-medium'>
            ${heading}
          </div>
          ${
            cd && !hidePercentChange
              ? `
            <div class='ml-12'>
              ${percentChangeHtml(d.value, cd.value)}
            </div>
          `
              : ""
          }
        </div>
        ${!!rows && rows.length > 0 ? rows.map((r) => dataRowHtml(r.label, r.formattedValue, false, r.color)).join("") : ""}
        ${!rows ? dataRowHtml(d.label, d.formattedValue) : ""}
        ${!rows && cd ? dataRowHtml(cd.label, cd.formattedValue, true) : ""}
      </div>
    `;
  },
  scatterplot(args) {
    const { xMeasurementLabel, yMeasurementLabel } = args;

    return function (d, axis) {
      return `
        <div class='bg-white rounded-md shadow border text-tiny px-2 py-1.5'>
          <div class='flex border-b pb-1.5 mb-1'>
            <div class='grow font-medium'>
              ${d.squad_name}
            </div>
          </div>
          <div class='flex items-center text-gray-700 text-tiny pt-1.5'>
            <div class='h-2 w-2 rounded-full bg-gray-300'>
            </div>
            <div class='ml-1.5 grow'>
              ${axis.y.tooltipLabel}
            </div>
            <div class='ml-12'>
              ${d.y}${yMeasurementLabel}
            </div>
          </div>
          <div class='flex items-center text-gray-700 text-tiny pt-1.5'>
            <div class='h-2 w-2 rounded-full bg-gray-300'>
            </div>
            <div class='ml-1.5 grow'>
              ${axis.x.tooltipLabel}
            </div>
            <div class='ml-12'>
              ${d.x}${xMeasurementLabel}
            </div>
          </div>
        </div>
      `;
    };
  },
  timeLoss(args) {
    const { heading, d, cd } = args;

    return `
      <div class='bg-white rounded-md shadow border text-tiny px-2 py-1.5'>
        <div class='flex border-b pb-1.5 mb-1'>
          <div class='grow font-medium'>
            ${heading}
          </div>
          ${
            cd
              ? `
            <div class='ml-12'>
            ${percentChangeHtml(d.value, cd.value, true)}
            </div>
          `
              : ""
          }
        </div>
        ${dataRowHtml(d.label, d.formattedValue)}
        ${cd ? dataRowHtml(cd.label, cd.formattedValue, true) : ""}
      </div>
    `;
  },
  onboarding(d) {
    return `
      <div class='bg-white rounded-md shadow border text-sm w-56 px-2.5 py-2'>
        <div class='font-medium'>
          ${d.name}
        </div>
        <div class='text-2xs text-gray-500'>
          Started ${d.start_date}
        </div>
        <div class='mt-3.5'>
          <div class='text-2xs text-gray-500'>
            ${d.label}
          </div>
          <div class='text-tiny'>
            ${d.value_display}
          </div>
        <div>
        ${
          d.squad_name
            ? `
          <div class='mt-3.5'>
            <div class='text-2xs text-gray-500'>
              Team
            </div>
            <div class='text-tiny'>
              ${d.squad_name}
            </div>
          </div>
        `
            : ""
        }
      </div>
    `;
  },
  attritionRisk(args) {
    const { heading, d } = args;

    return `
      <div class='bg-white rounded-md shadow border text-tiny px-2 py-1.5'>
        <div class='flex border-b pb-1.5 mb-1'>
          <div class='grow font-medium'>
            ${heading}
          </div>
        </div>
        ${dataRowHtml(d.label, d.formattedValue)}
        ${dataRowHtml("Contributors at risk", d.contributorCount)}
      </div>
    `;
  },
  participationTrend(args) {
    const { heading, d } = args;

    return `
      <div class='bg-white rounded-md shadow border text-tiny px-2 py-1.5'>
        <div class='flex border-b pb-1.5 mb-1'>
          <div class='grow font-medium'>
            ${heading}
          </div>
        </div>
        ${dataRowHtml(d.label, d.formattedValue)}
      </div>
    `;
  },
  priorityDiffTooltip(item, responseCount) {
    let percentage =
      !!responseCount && responseCount > 0
        ? ` (${Math.round(((item.priority * 1.0) / responseCount) * 100)}%)`
        : "";

    let tooltip = `${item.priority} ${item.priority == 1 ? "person" : "people"}${percentage} voted <span class='font-bold'>${item.name}</span> a top priority`;

    if (
      item.previous_priority_count == null ||
      item.previous_priority_rank == null ||
      item.priority_rank == item.previous_priority_rank
    )
      return (tooltip += ".");

    let changeLabel = "";

    if (item.priority_rank > item.previous_priority_rank) {
      changeLabel = "decreasing ";
    } else if (item.priority_rank < item.previous_priority_rank) {
      changeLabel = "increasing ";
    }

    tooltip += `, ${changeLabel} its rank by ${Math.abs(item.priority_rank - item.previous_priority_rank)} vs your previous snapshot.`;

    return tooltip;
  },
};

function dataRowHtml(label, formattedValue, comparison = false, color) {
  return `
    <div class='flex items-center text-gray-700 text-tiny pt-1.5'>
      <div class='h-2 w-2 rounded-full ${
        comparison ? "bg-gray-400" : "bg-blue-500"
      }' ${color ? "style='background-color:" + color + ";'" : ""}>
      </div>
      <div class='ml-1.5 grow'>
        ${label}
      </div>
      <div class='ml-12'>
        ${formattedValue}
      </div>
    </div>
  `;
}

function percentChangeHtml(newValue, originalValue, invert = false) {
  const pct = percentChange(newValue, originalValue);

  let colors, value;

  if (pct === 0) {
    colors = "text-gray-500";
    value = `0.0`;
  } else if ((pct > 0 && !invert) || (pct < 0 && invert)) {
    colors = "bg-blue-50 text-blue-500 border-blue-300";
    value = `${pct > 0 ? "+" : ""}${pct.toFixed(1)}`;
  } else {
    colors = "bg-yellow-50 text-yellow-500 border-yellow-300";
    value = `${pct > 0 ? "+" : ""}${pct.toFixed(1)}`;
  }

  return `
    <div class='border text-2xs font-medium rounded px-1.5 py-px ${colors}'>
      ${value}%
    </div>
  `;
}

function percentChange(newValue, originalValue) {
  if (originalValue === 0) return 0; // avoid dividing by zero

  const percentage = newValue / originalValue;

  return 100.0 * (percentage - 1);
}

function percentageOfResponses(item, positionCount, totalFieldCount) {
  if (item[totalFieldCount] === 0) return "0%";

  return `${Math.round((100 * item[positionCount]) / item[totalFieldCount])}%`;
}
