import tippy from "tippy.js";

export const tooltip = (el, { expression }) => {
  tippy(el, { content: expression, allowHTML: true });
};

export const tooltipDelay = (el, { expression }) => {
  tippy(el, { content: expression, allowHTML: true, delay: [250, 0] });
};

export const tooltipNoArrow = (el, { expression }) => {
  tippy(el, { content: expression, allowHTML: true, arrow: false });
};

export const tooltipNoArrowDelay = (el, { expression }) => {
  tippy(el, {
    content: expression,
    allowHTML: true,
    arrow: false,
    delay: [250, 0],
  });
};
