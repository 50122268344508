import { pluralize } from "@/lib/utils";
import { eqArrays, eqSets } from "../../shared/utils";
import {
  addCurrentPageEventListener,
  objectToQueryString,
} from "../../shared/utils";

export function filterMultiselect(args) {
  const {
    getFilters = () => null,
    itemsName,
    onChange,
    treatAllAsNone,
    updateOptionsEndpoint,
    updateOptionsEvent,
    remoteSearchPath,
  } = args;

  const selectedIds = new Set(args.selectedIds);

  let originalValue, rootIds, originalItems;

  return {
    selectedIds,
    disabledIds: new Set(),
    dynamicPrompt: null,
    isOpen: false,
    items: args.items,
    searchTermPresent: false,
    itemsUpdating: false,
    init() {
      originalItems = this.items;
      this.items = this.sortedItems();
      rootIds = new Set(this.items.map((s) => s.id));
      this.setPrompt();
      if (updateOptionsEvent) {
        addCurrentPageEventListener(updateOptionsEvent, () => {
          this.updateOptions();
        });
      }
    },
    get selectedIdsArr() {
      return Array.from(this.selectedIds);
    },
    updateOptions() {
      this.items = [];
      this.itemsUpdating = true;

      const filtersQueryString = objectToQueryString(getFilters());

      // API call to endpoint that must return JSON with `options` array.
      //   {"options": [{"id": "one", "name": "one"}, {"id": "two", "name": "two"]}
      fetch(`${updateOptionsEndpoint}${"?" + filtersQueryString}`)
        .then((res) => res.json())
        .then((data) => {
          const stillSelected = new Set();

          originalItems = data.options;
          originalItems.forEach((option) => {
            if (this.selectedIds.has(option.id)) stillSelected.add(option.id);
          });

          rootIds = new Set(data.options.map((s) => s.id));

          this.selectedIds = stillSelected;
          this.items = this.sortedItems();
          this.itemsUpdating = false;
          this.setPrompt();
        });
    },
    toggleDropdown() {
      if (this.isOpen) {
        return this.unfocus();
      }

      this.focus();
    },
    focus() {
      this.isOpen = true;

      originalValue = this.selectedIdsArr;

      const searchField = this.$root.querySelector(".x-searchInput");
      this.items = this.sortedItems();
      this.$nextTick(() => {
        searchField.focus();
      });
    },
    unfocus() {
      if (!this.isOpen) return;

      this.isOpen = false;

      const currentValue = this.selectedIdsArr;
      const changed = !eqArrays(originalValue, currentValue);
      const allSelected = eqSets(this.selectedIds, rootIds);

      this.$nextTick(() => {
        this.$refs.scrollContainer.scrollTop = 0;

        if (changed) {
          if (allSelected && treatAllAsNone) {
            onChange(this.$root, []);
          } else {
            onChange(this.$root, Array.from(currentValue));
          }
        }
      });
    },
    setPrompt() {
      if (eqSets(this.selectedIds, rootIds)) {
        this.dynamicPrompt = `All ${pluralize(itemsName, null)}`;
      } else if (this.selectedIds.size > 0) {
        this.dynamicPrompt = pluralize(itemsName, this.selectedIds.size);
      } else {
        this.dynamicPrompt = null;
      }
    },
    selectItem(id) {
      this.selectedIds.add(id);
    },
    deselectItem(id) {
      this.selectedIds.delete(id);
    },
    isSelected(id) {
      return this.selectedIds.has(id);
    },
    disableId(id) {
      this.disabledIds.add(id);
    },
    undisableId(id) {
      this.disabledIds.delete(id);
    },
    isDisabled(id) {
      return this.disabledIds.has(id);
    },
    toggleSelectItem(id) {
      if (this.isDisabled(id)) return;

      if (this.isSelected(id)) {
        this.deselectItem(id);
      } else {
        this.selectItem(id);
      }

      this.setPrompt();
    },
    selectAll() {
      this.items.forEach((item) => {
        this.selectItem(item.id);
      });

      this.setPrompt();
    },
    deselectAll() {
      this.selectedIds.clear();

      this.disabledIds.clear();

      this.setPrompt();
    },
    search(e) {
      const searchTerm = e.target.value;

      this.searchTermPresent = searchTerm.length > 0;

      if (remoteSearchPath) {
        this.itemsUpdating = true;
        fetch(
          `${remoteSearchPath}?q=${searchTerm}&selected=${this.selectedIdsArr.join()}`,
        )
          .then((response) => response.json())
          .then((data) => {
            this.items = data;
            originalItems = this.items;
            this.items = this.sortedItems();
            this.itemsUpdating = false;
          });
      } else {
        if (this.searchTermPresent) {
          this.items = originalItems.filter((item) => {
            return item.name.toLowerCase().includes(searchTerm.toLowerCase());
          });
          return;
        }
        this.items = this.sortedItems();
      }
    },
    sortedItems() {
      return originalItems.sort((a, b) => {
        const aSelected = this.isSelected(a.id) ? 1 : 0;
        const bSelected = this.isSelected(b.id) ? 1 : 0;
        return bSelected - aSelected;
      });
    },
  };
}
