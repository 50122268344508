const previousSnapshotOpts = [["vs_prev", "Previous"]];

const teamOpts = [["vs_org", "Org average"]];

const industryOpts = [
  ["vs_50th", "50th percentile"],
  ["vs_75th", "75th percentile"],
  ["vs_90th", "90th percentile"],
];

export function snapshotCompareTo(args) {
  let snapshots = args?.snapshots;
  // These next few lines ensure the data takes the current snapshot into account
  const currentSnapshotEncodedId = args?.currentSnapshot;
  const indexOfCurrent = snapshots.findIndex(
    (s) => s.encoded_id == currentSnapshotEncodedId,
  );
  snapshots = snapshots.slice(indexOfCurrent + 1);

  const olderSnapshotOpts = snapshots
    .slice(1)
    .map((s) => [`ss-${s?.encoded_id}`, s?.date]); // where the key for the URL param is getting set.

  const optionLabelsMap = new Map([
    ...previousSnapshotOpts,
    ...olderSnapshotOpts,
    ...teamOpts,
    ...industryOpts,
  ]);

  optionLabelsMap.set("vs_50th", "Industry 50th");
  optionLabelsMap.set("vs_75th", "Industry 75th");
  optionLabelsMap.set("vs_90th", "Industry 90th");

  return {
    expanded: false,
    previousSnapshotOpts: previousSnapshotOpts,
    olderSnapshotOpts,
    teamOpts,
    industryOpts,
    optionLabelsMap,
    completedSnapshots: snapshots,
    hidePreviousSnapshots: args?.hidePreviousSnapshots || false,
    toggleDropdown() {
      this.expanded = !this.expanded;
    },
    postProcessChange() {
      this.expanded = false;
    },
    isSelected(opt) {
      const previousSnapshotId =
        this.$store.snapshotResultFilters.previousSnapshotId;

      if (previousSnapshotId) {
        return opt == `ss-${previousSnapshotId}`;
      } else {
        return this.compareTo == opt;
      }
    },
    comparisonMenuLabel(value) {
      const previousSnapshotId =
        this.$store.snapshotResultFilters.previousSnapshotId;

      if (previousSnapshotId) {
        const mapValue = optionLabelsMap.get(`ss-${previousSnapshotId}`);
        return mapValue;
      } else {
        return optionLabelsMap.get(value);
      }
    },
  };
}
