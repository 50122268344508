import Alpine from "alpinejs";
import mask from "@alpinejs/mask";
import tippy, { followCursor, hideAll } from "tippy.js";
import "@hotwired/turbo-rails";
import * as d3 from "d3";
import {
  BarChart,
  BumpChart,
  LineChart,
  PieChart,
  ScatterChart,
  ScatterTimeChart,
  StackedBarChart,
  StackedAreaChart,
} from "@get-dx/d3-charts";

import "tippy.js/dist/tippy.css";
import "tippy.js/dist/border.css";

import "@/application/textarea_autoresize";
import "@/application/disable_enter";
import "@/application/userSearch";
import "@/application/snapshotHeatmap";
import "@/application/gauge";
import "@/application/accountAvatar";
import "@/application/pluralize";
import "@/application/targetRoleText";

// Magics
import { timeago, timeagoAbbreviated } from "@/application/magics/timeago";

// Custom Directives
import {
  tooltip,
  tooltipDelay,
  tooltipNoArrow,
  tooltipNoArrowDelay,
} from "@/application/directives/tooltip";
import {
  disableOnClick,
  disableOnValidClick,
} from "@/application/directives/disableOnClick";

// Data Directives
import { frontlineGroupPulse } from "@/application/components/frontline/frontlineGroupPulse";
import { frontlineGroupSetup } from "@/application/components/frontline/frontlineGroupSetup";
import { frontlineContributorStatus } from "@/application/components/frontline/frontlineContributorStatus";
import { frontlineUiPaginatedPrs } from "@/application/components/frontline/frontlineUiPaginatedPrs";
import { frontlineAsyncModal } from "@/application/components/frontline/frontlineAsyncModal";
import { frontlineGroupSelector } from "@/application/components/frontline/frontlineGroupSelector";
import { frontlineAlertSnoozeSelect } from "@/application/components/frontline/frontlineAlertSnoozeSelect";
import { accountSwitcher } from "@/application/components/accountSwitcher";
import { asyncSearchSelect } from "@/application/components/asyncSearchSelect";
import { areaSelect } from "@/application/components/areaSelect";
import { atlasArticle } from "@/application/components/atlasArticle";
import { atlasCatalog } from "@/application/components/atlasCatalog";
import { atlasEditor } from "@/application/components/atlasEditor";
import { bulkNudge } from "@/application/components/bulkNudge";
import { chartContainer } from "@/application/components/chartContainer";
import { codeBlock } from "@/application/components/codeBlock";
import { costEstimator } from "@/application/components/costEstimator";
import { customBehavioralQuestions } from "@/application/components/customBehavioralQuestions";
import { customSelect } from "@/application/components/customSelect";
import { datacloudAsyncCharts } from "@/application/components/datacloudAsyncCharts";
import { datacloudAsyncTable } from "@/application/components/datacloudAsyncTable";
import { datacloudDataTable } from "@/application/components/datacloudDataTable";
import { datacloudFilters } from "@/application/components/datacloudFilters";
import { datacloudSchema } from "@/application/components/datacloudSchema";
import { datacloudAllocationReport } from "@/application/components/datacloudAllocationReport";
import { dxiTeamBreakdown } from "@/application/components/dxiTeamBreakdown";
import { flatpickrRangePicker } from "@/application/components/flatpickrRangePicker";
import { dirtyForm } from "@/application/components/dirtyForm";
import { dataFileUpload } from "@/application/components/dataFileUpload";
import { emailConfirmation } from "@/application/components/emailConfirmation";
import { filterMultiselect } from "@/application/components/filterMultiselect";
import { heatmapNavigator } from "@/application/components/heatmapNavigator";
import { histogramChart } from "@/application/components/histogramChart";
import { insightsDrivers } from "@/application/components/insightsDrivers";
import { insightsKpis } from "@/application/components/insightsKpis";
import { insightsTeamFilter } from "@/application/components/insightsTeamFilter";
import { insightsWorkflows } from "@/application/components/insightsWorkflows";
import { intercomBarChart } from "@/application/components/intercomBarChart";
import { intercomFilters } from "@/application/components/intercomFilters";
import { intercomLineChart } from "@/application/components/intercomLineChart";
import { internalTool } from "@/application/components/internalTool";
import { keyDriversChart } from "@/application/components/keyDriversChart";
import { livePreview } from "@/application/components/livePreview";
import { notifications } from "@/application/components/notifications";
import { onboardingChart } from "@/application/components/onboardingChart";
import { onboardingResponseModal } from "@/application/components/onboardingResponseModal";
import { scatterplotBuilder } from "@/application/components/scatterplotBuilder";
import { messagePreview } from "@/application/components/messagePreview";
import { snapshotCompareTo } from "@/application/components/snapshotCompareTo";
import { snapshotHierarchyGroups } from "@/application/components/snapshotHierarchyGroups";
import { snapshotCsat } from "@/application/components/snapshotCsat";
import { snapshotDelivery } from "@/application/components/snapshotDelivery";
import { snapshotAttrition } from "@/application/components/snapshotAttrition";
import { snapshotIntakeAttributes } from "@/application/components/snapshotIntakeAttributes";
import { snapshotIntakeRating } from "@/application/components/snapshotIntakeRating";
import { snapshotIntakeRanking } from "@/application/components/snapshotIntakeRanking";
import { snapshotIntakeOutcomes } from "@/application/components/snapshotIntakeOutcomes";
import { snapshotIntakeInternalTools } from "@/application/components/snapshotIntakeInternalTools";
import { snapshotIntakeBehaviors } from "@/application/components/snapshotIntakeBehaviors";
import { snapshotIntakeAllocation } from "@/application/components/snapshotIntakeAllocation";
import { snapshotIntakeResearch } from "@/application/components/snapshotIntakeResearch";
import { snapshotRequestUpdate } from "@/application/components/snapshotRequestUpdate";
import { snapshotResponse } from "@/application/components/snapshotResponse";
import { snapshotResponseCountDetails } from "@/application/components/snapshotResponseCountDetails";
import { snapshotResultsLineChart } from "@/application/components/snapshotResultsLineChart";
import { snapshotSetupBehavioralQuestions } from "@/application/components/snapshotSetupBehavioralQuestions";
import { snapshotSentiment } from "@/application/components/snapshotSentiment";
import { snapshotSetup } from "@/application/components/snapshotSetup";
import { snapshotSetupCadenceAndDrivers } from "@/application/components/snapshotSetupCadenceAndDrivers";
import { snapshotSetupInternalTools } from "@/application/components/snapshotSetupInternalTools";
import { snapshotSetupAllocation } from "@/application/components/snapshotSetupAllocation";
import { snapshotSetupNudges } from "@/application/components/snapshotSetupNudges";
import { snapshotSetupProgram } from "@/application/components/snapshotSetupProgram";
import { snapshotWorkflows } from "@/application/components/snapshotWorkflows";
import { snapshotAllocationResult } from "@/application/components/snapshotAllocationResult";
import { allocationQuestionConfig } from "@/application/components/allocationQuestionConfig";
import { selfReportedTag } from "@/application/components/selfReportedTag";
import { snapshotResultsNav } from "@/application/components/snapshotResultsNav";
import { snapshotStatus } from "@/application/components/snapshotStatus";
import { snapshotStatusMetadata } from "@/application/components/snapshotStatusMetadata";
import { tagUsersTable } from "@/application/components/tagUsersTable";
import { triageResponsesModal } from "@/application/components/triageResponsesModal";
import { triageFormModal } from "@/application/components/triageFormModal";
import { triageResultsModal } from "@/application/components/triageResultsModal";
import { snapshotQuickjump } from "@/application/components/snapshotQuickjump";
import { squadMultiselect } from "@/application/components/squadMultiselect";
import { squadMultiselectV2 } from "@/application/components/squadMultiselectV2";
import { squadQuickjump } from "@/application/components/squadQuickjump";
import { squadsTree } from "@/application/components/squadsTree";
import { intercomStudyForm } from "@/application/components/intercomStudyForm";
import { tagMultiselect } from "@/application/components/tagMultiselect";
import { tagMultiselectV2 } from "@/application/components/tagMultiselectV2";
import { teamsList } from "@/application/components/teamsList";
import { unlinkedUsers } from "@/application/components/unlinkedUsers";
import { updateSnapshotSettings } from "@/application/components/updateSnapshotSettings";
import { userCsvUpload } from "@/application/components/userCsvUpload";
import { userDetail } from "@/application/components/userDetail";
import { userMultiselect } from "@/application/components/userMultiselect";
import { userProfile } from "@/application/components/userProfile";
import { userSelect } from "@/application/components/userSelect";
import { workflowResultDetails } from "@/application/components/workflowResultDetails";
import { studiesSetup } from "@/application/components/studies/studiesSetup";
import { studiesSurveySetup } from "@/application/components/studies/studiesSurveySetup";
import { studiesQuestionSummary } from "@/application/components/studies/studiesQuestionSummary";
import { studiesResponseSegmentSelect } from "@/application/components/studies/studiesResponseSegmentSelect";

// Stores
import { frontlinePrAlertsStore } from "@/application/components/frontline/frontlinePrAlertsStore";
import { snapshotResultFiltersStore } from "@/application/components/snapshotResultFiltersStore";
import { triageSquadManagerStore } from "@/application/components/triageSquadManagerStore";
import { pushNotification } from "@/application/pushNotification";

import { userAvatar } from "@/application/userAvatar";
import { initialsAvatar } from "@/application/initialsAvatar";
import { chartTooltipHtml } from "@/application/chartTooltipHtml";
import { ack } from "@/application/ack";

Alpine.plugin(mask);

tippy.setDefaultProps({
  plugins: [followCursor],
  theme: "dx",
});

tippy.hideAll = hideAll;

// Window objects and document listeners
window.tippy = tippy;
window.Alpine = Alpine;
window.d3 = d3;
window.BarChart = BarChart;
window.BumpChart = BumpChart;
window.LineChart = LineChart;
window.PieChart = PieChart;
window.ScatterChart = ScatterChart;
window.ScatterTimeChart = ScatterTimeChart;
window.StackedBarChart = StackedBarChart;
window.StackedAreaChart = StackedAreaChart;
window.pushNotification = pushNotification;
window.userAvatar = userAvatar;
window.initialsAvatar = initialsAvatar;
window.chartTooltipHtml = chartTooltipHtml;
window.ack = ack;

// Magics
Alpine.magic("timeagoAbbreviated", timeagoAbbreviated);
Alpine.magic("timeago", timeago);

// Custom Directives
Alpine.directive("tooltip", tooltip);
Alpine.directive("tooltip-delay", tooltipDelay);
Alpine.directive("tooltip-no-arrow", tooltipNoArrow);
Alpine.directive("tooltip-no-arrow-delay", tooltipNoArrowDelay);
Alpine.directive("disable-on-click", disableOnClick);
Alpine.directive("disable-on-valid-click", disableOnValidClick);

// Data Directives
Alpine.data("frontlineGroupPulse", frontlineGroupPulse);
Alpine.data("frontlineGroupSetup", frontlineGroupSetup);
Alpine.data("frontlineGroupSelector", frontlineGroupSelector);
Alpine.data("frontlineContributorStatus", frontlineContributorStatus);
Alpine.data("frontlineUiPaginatedPrs", frontlineUiPaginatedPrs);
Alpine.data("frontlineAsyncModal", frontlineAsyncModal);
Alpine.data("frontlineAlertSnoozeSelect", frontlineAlertSnoozeSelect);
Alpine.data("accountSwitcher", accountSwitcher);
Alpine.data("asyncSearchSelect", asyncSearchSelect);
Alpine.data("areaSelect", areaSelect);
Alpine.data("atlasArticle", atlasArticle);
Alpine.data("atlasCatalog", atlasCatalog);
Alpine.data("atlasEditor", atlasEditor);
Alpine.data("bulkNudge", bulkNudge);
Alpine.data("chartContainer", chartContainer);
Alpine.data("codeBlock", codeBlock);
Alpine.data("costEstimator", costEstimator);
Alpine.data("customSelect", customSelect);
Alpine.data("customBehavioralQuestions", customBehavioralQuestions);
Alpine.data("datacloudAsyncCharts", datacloudAsyncCharts);
Alpine.data("datacloudAsyncTable", datacloudAsyncTable);
Alpine.data("datacloudDataTable", datacloudDataTable);
Alpine.data("datacloudFilters", datacloudFilters);
Alpine.data("datacloudSchema", datacloudSchema);
Alpine.data("datacloudAllocationReport", datacloudAllocationReport);
Alpine.data("dirtyForm", dirtyForm);
Alpine.data("dataFileUpload", dataFileUpload);
Alpine.data("dxiTeamBreakdown", dxiTeamBreakdown);
Alpine.data("emailConfirmation", emailConfirmation);
Alpine.data("filterMultiselect", filterMultiselect);
Alpine.data("flatpickrRangePicker", flatpickrRangePicker);
Alpine.data("heatmapNavigator", heatmapNavigator);
Alpine.data("histogramChart", histogramChart);
Alpine.data("insightsDrivers", insightsDrivers);
Alpine.data("insightsKpis", insightsKpis);
Alpine.data("insightsTeamFilter", insightsTeamFilter);
Alpine.data("insightsWorkflows", insightsWorkflows);
Alpine.data("intercomBarChart", intercomBarChart);
Alpine.data("intercomFilters", intercomFilters);
Alpine.data("intercomLineChart", intercomLineChart);
Alpine.data("internalTool", internalTool);
Alpine.data("keyDriversChart", keyDriversChart);
Alpine.data("livePreview", livePreview);
Alpine.data("notifications", notifications);
Alpine.data("onboardingChart", onboardingChart);
Alpine.data("onboardingResponseModal", onboardingResponseModal);
Alpine.data("scatterplotBuilder", scatterplotBuilder);
Alpine.data("messagePreview", messagePreview);
Alpine.data("snapshotCompareTo", snapshotCompareTo);
Alpine.data("snapshotHierarchyGroups", snapshotHierarchyGroups);
Alpine.data("snapshotCsat", snapshotCsat);
Alpine.data("snapshotDelivery", snapshotDelivery);
Alpine.data("snapshotAttrition", snapshotAttrition);
Alpine.data("snapshotIntakeAttributes", snapshotIntakeAttributes);
Alpine.data("snapshotIntakeRating", snapshotIntakeRating);
Alpine.data("snapshotIntakeRanking", snapshotIntakeRanking);
Alpine.data("snapshotIntakeOutcomes", snapshotIntakeOutcomes);
Alpine.data("snapshotIntakeBehaviors", snapshotIntakeBehaviors);
Alpine.data("snapshotIntakeInternalTools", snapshotIntakeInternalTools);
Alpine.data("snapshotIntakeAllocation", snapshotIntakeAllocation);
Alpine.data("snapshotIntakeResearch", snapshotIntakeResearch);
Alpine.data("snapshotRequestUpdate", snapshotRequestUpdate);
Alpine.data("snapshotResponse", snapshotResponse);
Alpine.data("snapshotResponseCountDetails", snapshotResponseCountDetails);
Alpine.data("snapshotResultsLineChart", snapshotResultsLineChart);
Alpine.data("snapshotSetup", snapshotSetup);
Alpine.data(
  "snapshotSetupBehavioralQuestions",
  snapshotSetupBehavioralQuestions,
);
Alpine.data("snapshotSentiment", snapshotSentiment);
Alpine.data("snapshotWorkflows", snapshotWorkflows);
Alpine.data("snapshotSetupCadenceAndDrivers", snapshotSetupCadenceAndDrivers);
Alpine.data("snapshotSetupInternalTools", snapshotSetupInternalTools);
Alpine.data("snapshotSetupAllocation", snapshotSetupAllocation);
Alpine.data("snapshotSetupNudges", snapshotSetupNudges);
Alpine.data("snapshotSetupProgram", snapshotSetupProgram);
Alpine.data("snapshotAllocationResult", snapshotAllocationResult);
Alpine.data("allocationQuestionConfig", allocationQuestionConfig);
Alpine.data("selfReportedTag", selfReportedTag);
Alpine.data("snapshotResultsNav", snapshotResultsNav);
Alpine.data("snapshotStatus", snapshotStatus);
Alpine.data("snapshotStatusMetadata", snapshotStatusMetadata);
Alpine.data("tagUsersTable", tagUsersTable);
Alpine.data("triageResponsesModal", triageResponsesModal);
Alpine.data("triageFormModal", triageFormModal);
Alpine.data("triageResultsModal", triageResultsModal);
Alpine.data("snapshotQuickjump", snapshotQuickjump);
Alpine.data("squadMultiselect", squadMultiselect);
Alpine.data("squadMultiselectV2", squadMultiselectV2);
Alpine.data("squadQuickjump", squadQuickjump);
Alpine.data("squadsTree", squadsTree);
Alpine.data("intercomStudyForm", intercomStudyForm);
Alpine.data("tagMultiselect", tagMultiselect);
Alpine.data("tagMultiselectV2", tagMultiselectV2);
Alpine.data("teamsList", teamsList);
Alpine.data("unlinkedUsers", unlinkedUsers);
Alpine.data("updateSnapshotSettings", updateSnapshotSettings);
Alpine.data("userCsvUpload", userCsvUpload);
Alpine.data("userDetail", userDetail);
Alpine.data("userMultiselect", userMultiselect);
Alpine.data("userProfile", userProfile);
Alpine.data("userSelect", userSelect);
Alpine.data("workflowResultDetails", workflowResultDetails);
Alpine.data("studiesSetup", studiesSetup);
Alpine.data("studiesSurveySetup", studiesSurveySetup);
Alpine.data("studiesQuestionSummary", studiesQuestionSummary);
Alpine.data("studiesResponseSegmentSelect", studiesResponseSegmentSelect);

// Stores
Alpine.store("snapshotResultFilters", snapshotResultFiltersStore);
Alpine.store("frontlinePrAlerts", frontlinePrAlertsStore);
Alpine.store("triageSquadManager", triageSquadManagerStore);

Alpine.start();
