import { objectToQueryString } from "../../../shared/utils";

export function studiesSetup(args) {
  return {
    study: args.study,
    audiencePreview: { initiallyLoaded: false, loaded: false },
    init() {
      this.loadPreview();

      const watchedAttrs = [
        "study.target_audience",
        "study.target_role",
        "study.squad_ids",
        "study.tag_ids",
        "study.exclude_recent_recipients",
        "study.snapshot_response_segment",
        "study.snapshot_response_segment_item_id",
        "study.snapshot_response_segment_item_type",
      ];

      this.$watch(watchedAttrs.join(", "), () => this.loadPreview());
    },
    loadPreview() {
      const {
        target_audience,
        target_role,
        squad_ids,
        tag_ids,
        exclude_recent_recipients,
        snapshot_response_segment_item_type,
        snapshot_response_segment_item_id,
        snapshot_response_segment,
      } = this.study;

      const queryString = objectToQueryString({
        target_audience,
        target_role,
        squad_ids: squad_ids.join(","),
        tag_ids: tag_ids.join(","),
        exclude_recent_recipients,
        snapshot_response_segment_item_type,
        snapshot_response_segment_item_id,
        snapshot_response_segment,
      });

      this.audiencePreview.loaded = false;

      fetch(`${args.previewEndpoint}?${queryString}`)
        .then((response) => response.json())
        .then((data) => {
          this.audiencePreview = Object.assign(data, {
            loaded: true,
            initiallyLoaded: true,
          });
        });
    },
    handleResponseSegmentChange(val) {
      this.study.snapshot_response_segment_item_type = val.item?.type;
      this.study.snapshot_response_segment_item_id = val.item?.id;
      this.study.snapshot_response_segment = val.segment?.id;
    },

    // Stuff for manual audience form
    manualAudienceFormOpen: args.manualAudienceFormOpen,
    useCsv: args.useCsv,
    filename: null,
    emailList: "",
    removeFile() {
      document.getElementById("file-input").value = null;
      this.filename = null;
    },
    handleFile(e) {
      const file = e.target.files[0];
      this.filename = file.name;
    },
    get manualListFormValid() {
      return (
        (this.useCsv && !!this.filename) || (!this.useCsv && !!this.emailList)
      );
    },
    get isValid() {
      return !!this.audiencePreview?.total && this.audiencePreview.total > 0;
    },
  };
}
