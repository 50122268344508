import { LineChart } from "@get-dx/d3-charts";
import {
  addCurrentPageEventListener,
  generatePreviousComparisonValues,
  generateBenchmarkComparisonValues,
} from "../../shared/utils";
import { chartTooltipHtml } from "../chartTooltipHtml";

function generateComparisonValues(kpi, benchmark) {
  let comparisonValues;

  if (benchmark === "previous_mark" && kpi.values.length > 1) {
    comparisonValues = generatePreviousComparisonValues(kpi);
  } else if (benchmark !== "previous_mark") {
    comparisonValues = generateBenchmarkComparisonValues(
      kpi,
      "factor",
      benchmark,
    );
  }

  return comparisonValues;
}

export function insightsKpis(args) {
  const { data, xStartDrag, xStartKpis, xEnd } = args;

  const charts = data.map((kpi) => {
    const benchmark = localStorage.getItem("trendCompareTo") || "previous_mark";
    const startDate = kpi.factor.time_loss ? xStartDrag : xStartKpis;
    const minVal = Math.min(
      ...kpi.values
        .map((v) => v.value)
        .concat(
          kpi.factor.time_loss
            ? kpi.factor.benchmark_90
            : kpi.factor.benchmark_50,
        ),
    );
    const maxVal = Math.max(
      ...kpi.values
        .map((v) => v.value)
        .concat(
          kpi.factor.time_loss
            ? kpi.factor.benchmark_50
            : kpi.factor.benchmark_90,
        ),
    );

    const attrs = {
      elChart: document.getElementById(`kpi-${kpi.factor.id}-chart`),
      startDate: startDate,
      endDate: xEnd,
      showXAxisTicks: false,
      tooltipHtml(d, cd) {
        const params = {
          heading: kpi.factor.name,
          d: {
            label: d.label,
            value: d.value,
            formattedValue: d.value,
          },
        };

        if (cd) {
          params.cd = {
            label: cd.label,
            value: cd.value,
            formattedValue: cd.value,
          };
        }

        return kpi.factor.time_loss
          ? chartTooltipHtml.timeLoss(params)
          : chartTooltipHtml.default(params);
      },
      yAxisMin: minVal * 0.8,
      yAxisMax: maxVal * 1.2,
      values: kpi.values,
    };

    if (benchmark && (kpi.factor[benchmark] || benchmark === "previous_mark")) {
      attrs.comparisonValues = generateComparisonValues(kpi, benchmark);
    }

    return new LineChart(attrs);
  });

  return {
    charts,
    init() {
      addCurrentPageEventListener("dx:compareTo-change", (event) => {
        this.charts.forEach((chart, index) => {
          const kpi = data[index];
          const comparisonValues = generateComparisonValues(
            kpi,
            event.detail.benchmark,
          );
          chart.comparisonValues = comparisonValues;
          chart.redraw();
        });
      });
    },
  };
}
