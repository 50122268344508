export function studiesResponseSegmentSelect(args) {
  const { factors, internalTools, study, onChange } = args;

  let options = [];

  if (factors.items.length) {
    options.push({ is_group_label: true, name: "Drivers", id: "0" });
    options = options.concat(factors.items);
  }

  if (internalTools.items.length) {
    options.push({ is_group_label: true, name: "CSAT", id: "1" });
    options = options.concat(internalTools.items);
  }

  return {
    searchResults: [],
    expanded: false,
    stagedItem: undefined,
    stagedSegment: undefined,
    selectedItem: undefined, // For keeping track of actual selection while dropdown is being interacted with
    selectedSegment: undefined, // For keeping track of actual selection while dropdown is being interacted with
    view: "items", // "items" || "segments"
    init() {
      let selectedItem;
      let selectedSegment;

      const {
        snapshot_response_segment: segment,
        snapshot_response_segment_item_type: itemType,
        snapshot_response_segment_item_id: itemId,
      } = study;

      if (itemType == "Factor") {
        selectedItem = factors.items.find((i) => i.id === itemId);
        selectedSegment = factors.segments.find((s) => s.id === segment);
      } else if (itemType == "InternalTool") {
        selectedItem = internalTools.items.find((i) => i.id === itemId);
        selectedSegment = internalTools.segments.find((s) => s.id === segment);
      }

      if (selectedItem && selectedSegment) {
        this.selectedItem = selectedItem;
        this.selectedSegment = selectedSegment;
      }
    },
    toggleDropdown() {
      if (this.expanded) return this.unfocus();
      this.focus();
    },
    focus() {
      this.searchResults = options;
      this.view = "items";
      this.expanded = true;

      this.stagedItem = this.selectedItem;
      this.stagedSegment = this.selectedSegment;

      this.$nextTick(() => {
        this.$refs.searchInput?.focus();
      });
    },
    unfocus() {
      if (!this.expanded) return;

      this.stagedItem = null;
      this.stagedSegment = null;
      this.expanded = false;

      this.$nextTick(() => {
        if (this.$refs.searchInput) {
          this.$refs.searchInput.value = "";
          this.$refs.searchInput?.blur();
        }
      });
    },
    segmentsForItem(item) {
      if (item?.type == "Factor") {
        return factors.segments;
      } else if (item?.type == "InternalTool") {
        return internalTools.segments;
      } else {
        [];
      }
    },
    selectItem(item) {
      this.stagedItem = item;
      this.view = "segments";
    },
    selectSegment(segment) {
      this.selectedItem = this.stagedItem;
      this.selectedSegment = segment;

      onChange({ item: this.selectedItem, segment: this.selectedSegment });
    },
    isSelected(item, segment) {
      if (!item || !segment) return false;

      return (
        this.selectedItem?.id == item.id &&
        this.selectedSegment?.id == segment.id
      );
    },
    deselect() {
      this.selectedItem = null;
      this.selectedSegment = null;
      this.unfocus();

      onChange({ item: this.selectedItem, segment: this.selectedSegment });
    },
    search() {
      const searchTerm = this.$refs.searchInput.value;

      if (searchTerm.length > 0) {
        const matchingOptions = options.filter((option) => {
          return (
            option.name.toLowerCase().includes(searchTerm.toLowerCase()) &&
            !option.is_group_label
          );
        });

        this.searchResults = matchingOptions;
        return;
      }

      this.searchResults = options;
    },
  };
}
