import {
  addCurrentPageEventListener,
  objectToQueryString,
  getWithExpiry,
  setWithExpiry,
} from "../../shared/utils";

export function datacloudAsyncTable(args) {
  const { endpoint, getFilters, setFilter } = args;
  let isFetching = false;

  return {
    endpoint,
    hideCommits: getWithExpiry("hideCommits"),
    hidePulls: getWithExpiry("hidePulls"),
    hideReviews: getWithExpiry("hideReviews"),
    init() {
      addCurrentPageEventListener("dx:datacloud-filters-changed", () => {
        this.updateSession();
      });
    },
    updateSession() {
      const filtersQueryString = objectToQueryString(getFilters());

      if (isFetching) return;

      isFetching = true;
      fetch(`${this.endpoint}/update_session${"?" + filtersQueryString}`).then(
        () => {
          const url = new URL(window.location);
          window.Turbo.visit(url.toString(), { action: "replace" });
        },
      );
    },
    setLogRangeStart(store, weekStartDate) {
      setFilter("log_range_start", weekStartDate);

      this.updateSession();
    },
    toggleDots(fieldKey) {
      const currentValue = getWithExpiry(fieldKey);

      setWithExpiry(fieldKey, !currentValue, 60 * 60 * 24);

      if (fieldKey == "hideCommits") this.hideCommits = !this.hideCommits;
      if (fieldKey == "hidePulls") this.hidePulls = !this.hidePulls;
      if (fieldKey == "hideReviews") this.hideReviews = !this.hideReviews;
    },
  };
}
