import { sortFunc } from "../../shared/utils";

// NOTE: This logic should mostly stay in sync with app/javascript/application/components/triageFormModal.js.
// The other file is used for the form version of the Triage modal shown to team leads.

export function triageResponsesModal({
  triageSelectionsUrl,
  snapshotBaseUrl,
  minResponseThreshold,
  snapshotSquadId,
}) {
  return {
    open: false,
    loaded: false,
    snapshotItems: [],
    selections: [],
    responseCount: 0,
    minResponseThreshold,
    comments: [],
    commentsOpen: false,
    commentsDriver: null,
    commentsLoaded: false,
    compareTo: "vs_prev",
    showOrgComparision: false,
    openModal() {
      this.open = true;
      this.fetchData();
    },
    fetchData() {
      fetch(`${triageSelectionsUrl}?squad_id=${snapshotSquadId}`)
        .then((response) => response.json())
        .then((resp) => {
          resp.forEach((triageResult) => {
            this.selections[triageResult.factor_id] = triageResult.selection;
          });
          this.fetchSnapshotItems();
        });
    },
    fetchSnapshotItems() {
      fetch(`${snapshotBaseUrl}/items?squad_ids=${snapshotSquadId}&branch=`)
        .then((response) => response.json())
        .then((resp) => {
          const sortedItems = resp.result_items
            .filter((i) => i.current_snapshot)
            .sort(sortFunc("priorityScoreAndCount"));

          const seenNames = [];
          const uniqueItems = [];
          sortedItems.forEach((item) => {
            if (!seenNames.includes(item.name.toLowerCase())) {
              uniqueItems.push(item);
              seenNames.push(item.name.toLowerCase());
            }
          });

          this.snapshotItems = uniqueItems.slice(0, 16);
          this.responseCount = resp.response_count;
          this.loaded = true;
        });
    },
    openComments(factorId) {
      this.commentsLoaded = false;
      this.commentsOpen = true;
      this.commentsDriver = this.snapshotItems.find((i) => i.id == factorId);

      fetch(
        `${snapshotBaseUrl}/comments?squad_ids=${snapshotSquadId}&factor_id=${factorId}&branch=`,
      )
        .then((response) => response.json())
        .then((resp) => {
          this.comments = resp;
          this.commentsLoaded = true;
        });
    },
    get maxPriority() {
      const arr = this.snapshotItems.map((i) => i.priority);

      return Math.max(...arr);
    },
    get maxDriverScore() {
      const arr = this.snapshotItems.map((i) => i.score);

      return Math.max(...arr);
    },
    percentageOfResponses(item, positionCount) {
      if (item.count === 0) return "0%";

      return `${Math.round((100 * item[positionCount]) / item.count)}%`;
    },
    changeComparison(compareTo) {
      this.compareTo = compareTo;
    },
    calculateItemComparison(item) {
      if (this.compareTo == "vs_50th") {
        if (item.benchmark_50 == null) return null;
        return item.score - item.benchmark_50;
      } else if (this.compareTo == "vs_75th") {
        if (item.benchmark_75 == null) return null;
        return item.score - item.benchmark_75;
      } else if (this.compareTo == "vs_90th") {
        if (item.benchmark_90 == null) return null;
        return item.score - item.benchmark_90;
      } else if (this.compareTo == "vs_prev") {
        if (item.previous_score == null) return null;
        return item.score - item.previous_score;
      }
    },
  };
}
