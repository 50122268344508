import flatpickr from "flatpickr";
import dayjs from "dayjs";
import quarterOfYear from "dayjs/plugin/quarterOfYear";

dayjs.extend(quarterOfYear);

export function flatpickrRangePicker(args) {
  let { startDate, endDate, range, onChange, maxDate, minDate } = args;

  const dateRanges = {
    l7d: [dayjs().startOf("day").subtract(7, "days"), dayjs()],
    l14d: [dayjs().startOf("day").subtract(14, "days"), dayjs()],
    l4w: [dayjs().startOf("day").subtract(4, "weeks"), dayjs()],
    l3m: [dayjs().startOf("day").subtract(3, "months"), dayjs()],
    l12m: [dayjs().startOf("day").subtract(12, "months"), dayjs()],
    mtd: [dayjs().startOf("month"), dayjs()],
    qtd: [dayjs().startOf("quarter"), dayjs()],
    ytd: [dayjs().startOf("year"), dayjs()],
    last_month: [
      dayjs().subtract(1, "month").startOf("month"),
      dayjs().subtract(1, "month").endOf("month"),
    ],
    last_quarter: [
      dayjs().subtract(1, "quarter").startOf("quarter"),
      dayjs().subtract(1, "quarter").endOf("quarter"),
    ],
    last_year: [
      dayjs().subtract(1, "year").startOf("year"),
      dayjs().subtract(1, "year").endOf("year"),
    ],
  };

  const rangeLabels = {
    l7d: "Last 7 days",
    l14d: "Last 14 days",
    l4w: "Last 4 weeks",
    l3m: "Last 3 months",
    l12m: "Last 12 months",
    mtd: "This month",
    qtd: "This quarter",
    ytd: "This year",
    last_month: "Last month",
    last_quarter: "Last quarter",
    last_year: "Last year",
    custom: "Custom",
  };

  if (!startDate || !endDate || range !== "custom") {
    range = range || "l4w";
    startDate = dateRanges[range]?.[0];
    endDate = dateRanges[range]?.[1];

    onChange(
      dayjs(startDate).format("YYYY-MM-DD"),
      dayjs(endDate).format("YYYY-MM-DD"),
      range,
    );
  }

  function showYear(selectedDates) {
    const currentYear = dayjs().year();
    const startYear = dayjs(selectedDates[0]).year();
    const endYear = dayjs(selectedDates[1]).year();

    return startYear !== currentYear || endYear !== currentYear;
  }

  function dayjsFormat(selectedDates) {
    return showYear(selectedDates)
      ? [
          dayjs(selectedDates[0]).format("MMM D, YYYY"),
          dayjs(selectedDates[1]).format("MMM D, YYYY"),
        ]
      : [
          dayjs(selectedDates[0]).format("MMM D"),
          dayjs(selectedDates[1]).format("MMM D"),
        ];
  }

  return {
    range: range,
    startDate: startDate,
    endDate: endDate,
    rangeLabels: rangeLabels,
    flatpickrInstance: null,

    init() {
      this.initializeFlatpickr();
    },

    initializeFlatpickr() {
      // Destroy existing Flatpickr instance if it exists
      if (this.flatpickrInstance) {
        this.flatpickrInstance.destroy();
      }

      // Initialize Flatpickr with date range
      this.flatpickrInstance = flatpickr(this.$refs.dateRange, {
        locale: {
          rangeSeparator: "–",
        },
        mode: "range",
        dateFormat: showYear([this.startDate, this.endDate]) ? "M j, Y" : "M j",
        defaultDate: dayjsFormat([this.startDate, this.endDate]),
        nextArrow: "->",
        prevArrow: "<-",
        maxDate,
        minDate,
        onChange: (selectedDates) => {
          if (selectedDates.length === 2) {
            this.startDate = dayjs(selectedDates[0]).format("YYYY-MM-DD");
            this.endDate = dayjs(selectedDates[1]).format("YYYY-MM-DD");
            onChange(this.startDate, this.endDate, "custom");

            // Reinitialize Flatpickr with potentially new dateFormat
            this.initializeFlatpickr();
          }
        },
      });
    },
    onSelectedRangeChange(selectedRange) {
      if (selectedRange === "custom") {
        return;
      }

      this.startDate = dayjs(dateRanges[selectedRange][0]).format("YYYY-MM-DD");
      this.endDate = dayjs(dateRanges[selectedRange][1]).format("YYYY-MM-DD");
      onChange(
        dateRanges[selectedRange][0].format("YYYY-MM-DD"),
        dateRanges[selectedRange][1].format("YYYY-MM-DD"),
        selectedRange,
      );
      this.initializeFlatpickr();
      this.flatpickrInstance.setDate(dayjsFormat(dateRanges[selectedRange]));
      this.range = selectedRange;
    },
    showYearWidthClass() {
      const selectedDates = [this.startDate, this.endDate];
      return showYear(selectedDates) ? "w-[14rem]" : "w-[9rem]";
    },
  };
}
