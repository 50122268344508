import { postData, groupBy } from "../../shared/utils";
import { ack } from "../ack";

export function snapshotIntakeRanking(args) {
  const {
    autosaveUrl,
    commentsRequired,
    disableComments,
    itemsDict,
    minSelections,
    maxSelections,
    ratingOptions,
    introShown,
    ackKey,
    commentGuidelines,
    popupText,
    popupButton,
  } = args;

  const groupedItems = groupBy(Object.values(itemsDict), "group");

  return {
    itemsDict,
    introShown,
    focusedItem: null,
    get ratingOptions() {
      return ratingOptions;
    },
    get groupedItems() {
      return groupedItems;
    },
    get selectedItems() {
      return Object.values(this.itemsDict).filter((item) => item.important);
    },
    needsComments() {
      let commentsCount = 0;

      for (const i in this.selectedItems) {
        const { id } = this.selectedItems[i];
        const len = this.itemsDict[id]?.text?.length || 0;

        if (len > 1) commentsCount++;
      }

      switch (commentsRequired) {
        case "none":
          return false;
        case "one":
          return commentsCount < 1;
        case "all":
          return commentsCount !== this.selectedItems.length;
      }
    },
    isSelected(id) {
      return this.itemsDict[id].important;
    },
    isDisabled(id) {
      return this.selectedItems.length >= maxSelections && !this.isSelected(id);
    },
    isFocused(id) {
      return this.focusedItem == id;
    },
    formValid() {
      if (this.selectedItems.length < minSelections) return false;

      if (this.needsComments()) return false;

      return true;
    },
    commentPlaceholder() {
      switch (commentsRequired) {
        case "none":
          return commentGuidelines.none;
        case "one":
          if (this.needsComments()) {
            return commentGuidelines.one;
          }
          return commentGuidelines.none;
        case "all":
          return commentGuidelines.all;
      }
    },
    autosave(id) {
      const { factor_id, important, is_public, text } = this.itemsDict[id];

      if (autosaveUrl) {
        postData(autosaveUrl, { factor_id, important, is_public, text });
      }
    },
    onSelect(el, id) {
      if (!this.isSelected(id)) {
        this.itemsDict[id].important = true;

        this.$nextTick(() => {
          if (disableComments) return;

          const textarea = el.closest(".x-item").querySelector("textarea");

          this.focusedItem = id;

          if (this.introShown) {
            textarea?.focus();
            return;
          }

          const that = this;

          setTimeout(() => {
            const textareaContainer = textarea.closest(".x-textarea-container");

            window
              .introJs()
              .setOptions({
                disableInteraction: false,
                exitOnEsc: false,
                exitOnOverlayClick: false,
                helperElementPadding: 0,
                showBullets: false,
                doneLabel: popupButton,
                steps: [
                  {
                    element: textareaContainer,
                    intro: popupText,
                  },
                ],
              })
              .start()
              .oncomplete(function () {
                that.introShown = true;

                ack(ackKey);

                textarea.focus();
              });
          }, 250);
        });
      } else {
        this.itemsDict[id].important = false;
      }

      this.autosave(id);
    },
  };
}
