import { LineChart } from "@get-dx/d3-charts";
import {
  addCurrentPageEventListener,
  generateBenchmarkComparisonValues,
  generatePreviousComparisonValues,
} from "../../shared/utils";
import { chartTooltipHtml } from "../chartTooltipHtml";

function generateComparisonValues(driver, benchmark) {
  let comparisonValues = [];

  if (benchmark === "previous_mark" && driver.values.length > 1) {
    comparisonValues = generatePreviousComparisonValues(driver);
  } else if (benchmark !== "previous_mark") {
    comparisonValues = generateBenchmarkComparisonValues(
      driver,
      "factor",
      benchmark,
    );
  }

  return comparisonValues;
}

export function insightsDrivers(args) {
  const { data, xStart, xEnd } = args;

  const charts = data.map((driver) => {
    const benchmark = localStorage.getItem("trendCompareTo") || "previous_mark";
    const minVal = Math.min(
      ...driver.values.map((v) => v.value).concat(driver.factor.benchmark_50),
    );
    const maxVal = Math.max(
      ...driver.values.map((v) => v.value).concat(driver.factor.benchmark_90),
    );

    const attrs = {
      elChart: document.getElementById(`driver-${driver.factor.id}-chart`),
      startDate: xStart,
      endDate: xEnd,
      tooltipHtml(d, cd) {
        const params = {
          heading: driver.factor.name,
          d: {
            label: d.label,
            value: d.value,
            formattedValue: d.value,
          },
        };

        if (cd) {
          params.cd = {
            label: cd.label,
            value: cd.value,
            formattedValue: cd.value,
          };
        }

        return chartTooltipHtml.default(params);
      },
      yAxisMin: minVal * 0.8,
      yAxisMax: maxVal * 1.2,
      showXAxisTicks: false,
      values: driver.values,
    };

    if (
      benchmark &&
      (driver.factor[benchmark] || benchmark === "previous_mark")
    ) {
      attrs.comparisonValues = generateComparisonValues(driver, benchmark);
    }

    return new LineChart(attrs);
  });

  return {
    charts,
    init() {
      addCurrentPageEventListener("dx:compareTo-change", (event) => {
        this.charts.forEach((chart, index) => {
          const driver = data[index];

          if (
            driver.factor[event.detail.benchmark] ||
            event.detail.benchmark === "previous_mark"
          ) {
            const comparisonValues = generateComparisonValues(
              driver,
              event.detail.benchmark,
            );
            chart.comparisonValues = comparisonValues;
            chart.redraw();
          } else {
            chart.comparisonValues = [];
            chart.redraw();
          }
        });
      });
    },
  };
}
