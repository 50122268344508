import { BarChart, PieChart } from "@get-dx/d3-charts";
import tippy from "tippy.js";
import {
  objectToQueryString,
  setQueryStringParam,
  getQueryStringParams,
} from "../../shared/utils";

const _SS_ALLOC_COLOR_ORDER = [
  [129, 140, 248], // Indigo 400
  [96, 165, 250], // Blue 400
  [56, 189, 248], // Sky 400
  [34, 211, 238], // Cyan 400
  [45, 212, 191], // Teal 400
  [52, 211, 153], // Emerald 400
  [74, 222, 128], // Green 400
  [163, 230, 53], // Lime 400
  [250, 204, 21], // Yellow 400
  [251, 146, 60], // Orange 400
  [248, 113, 113], // Red 400
  [251, 113, 133], // Rose 400
  [244, 114, 182], // Pink 400
  [232, 121, 249], // Fuchsia 400
  [167, 139, 250], // Violet 400
  [192, 132, 252], // Purple 400
];
const _SS_ALLOC_COLOR_OTHER = [209, 213, 219]; // Gray 300

export function snapshotAllocationResult(args) {
  const { endpoint, chartElId, questionItemOverrides } = args;

  return {
    loaded: false,
    updating: false,
    data: {},
    chartType: getQueryStringParams().get(chartElId) || "pie",
    chartData: [],
    colors: {},
    tableSort: { col: "group_name", dir: "asc" },
    init() {
      this.load();

      this.$watch("$store.snapshotResultFilters.hierarchyGroup", () => {
        this.load();
      });
    },
    get groupTitle() {
      return this.sortedValues()[0]?.group_title;
    },
    load() {
      const queryString = objectToQueryString({
        squad_ids: this.$store.snapshotResultFilters.squadIds.join(","),
        tag_ids: this.$store.snapshotResultFilters.tagIds.join(","),
        hg: this.$store.snapshotResultFilters.hierarchyGroup?.encoded_id,
        atg: this.$store.snapshotResultFilters.aggregationTagGroup?.encoded_id,
        branch: this.$store.snapshotResultFilters.branch,
      });

      this.updating = this.loaded;

      fetch(`${endpoint}?${queryString}`)
        .then((response) => response.json())
        .then((data) => {
          this.data.totals = data.totals;
          this.data.values = data.values;
          this.processData();

          this.loaded = true;
          this.updating = false;

          this.$nextTick(() => {
            this.drawChart();
          });
        });
    },
    processData() {
      this.chartData = [];

      this.data.totals.forEach((item, idx) => {
        const color =
          item.name === "Other"
            ? _SS_ALLOC_COLOR_OTHER
            : _SS_ALLOC_COLOR_ORDER[idx];

        this.colors[item.id] = color;

        let label = this.questionItemLabel(item);
        if (label.length > 20) {
          label = label.slice(0, 17) + "...";
        }

        this.chartData.push({
          name: label,
          label: label,
          fullName: this.questionItemLabel(item),
          value: item.pct,
          color: `rgb(${color.join(",")})`,
        });
      });
    },
    drawChart() {
      const el = document.getElementById(chartElId);
      if (!el) return;

      el.innerHTML = null;

      if (this.chartType == "bar") {
        this.drawBarChart(el);
      } else {
        this.drawPieChart(el);
      }
    },
    drawBarChart(el) {
      new BarChart({
        elChart: el,
        values: this.chartData,
        showYAxisTickLabels: true,
        showYAxisTicks: false,
        showYAxisLine: true,
        showXAxisTickLabels: true,
        showXAxisTicks: false,
        showXAxisLine: true,
        maxBarWidth: 300,
        paddingOuter: 0.1,
        axis: { y: { max: 100 } },
        yAxisTickLabelFormat: (d) => `${d}%`,
        tooltipHtml: this.chartTooltip.bind(this),
      });
    },
    drawPieChart(el) {
      new PieChart({
        elChart: el,
        values: this.chartData,
        minAngleForValueLabel: 361, // Don't show value labels
        tooltipHtml: this.chartTooltip.bind(this),
      });
    },
    chartTooltip(d) {
      let formatValFunc = this.formatVal.bind(this);
      return `
        <div class='bg-white rounded-md shadow border text-tiny px-2 py-1.5' style='min-width: 80px'>
          <div class='flex border-b pb-1.5 mb-1.5'>
            <div class='grow font-medium'>
              ${d.fullName}
            </div>
          </div>
          ${formatValFunc(d.value)}
        </div>
      `;
    },
    changeChartType(chartType) {
      this.chartType = chartType;
      setQueryStringParam(chartElId, chartType);

      this.$nextTick(() => {
        this.drawChart();
      });
    },
    changeSquadIds(squadIds) {
      this.$store.snapshotResultFilters.squadIds = squadIds;
      setQueryStringParam("squad_ids", squadIds);

      this.load();
    },
    changeTagIds(tagIds) {
      this.$store.snapshotResultFilters.tagIds = tagIds;
      setQueryStringParam("tag_ids", tagIds);

      this.load();
    },
    questionItemLabel(item) {
      return questionItemOverrides[item.id] || item.name;
    },
    formatVal(val) {
      // Call parseFloat 2x to get rid of trailing zeros
      return parseFloat(parseFloat(val).toFixed(0)) + "%";
    },
    tooltipIfTruncated(e, fullText) {
      const el = e.target;

      if (el && el.offsetWidth < el.scrollWidth) {
        tippy(el, {
          content: fullText,
          delay: [250, 0],
        });
      }
    },
    sortableHeader(column) {
      const isCurrentSort = column === this.tableSort.col;
      const isAsc = this.tableSort.dir === "asc";

      return `
        <div class="text-gray-300 flex flex-col -space-y-1.5 text-xs -mb-px">
          <i class="-mb-px bx bx-caret-up" :class="{'text-gray-500': ${
            isCurrentSort && isAsc
          }}"></i>
          <i class="bx bx-caret-down" :class="{'text-gray-500': ${
            isCurrentSort && !isAsc
          }}"></i>
        </div>
      `;
    },
    sortedValues() {
      let list = this.data.values;

      const isDesc = this.tableSort.dir === "desc";

      if (this.tableSort.col === "group_name") {
        list = list.sort((a, b) => {
          return isDesc
            ? b.group_name.localeCompare(a.group_name)
            : a.group_name.localeCompare(b.group_name);
        });
      } else if (this.tableSort.col === "sample_size") {
        list = list.sort((a, b) => {
          const aSize = a["sample_size"] || 0;
          const bSize = b["sample_size"] || 0;
          return isDesc ? bSize - aSize : aSize - bSize;
        });
      } else {
        list = list.sort((a, b) => {
          const aVal = a.items[this.tableSort.col];
          const bVal = b.items[this.tableSort.col];
          // Sort by numeric values, then hidden responses always after values, then nulls always last
          if (aVal == null) return 1;
          if (bVal == null) return -1;
          if (aVal == "hidden" && bVal != "hidden") return 1;
          if (bVal == "hidden" && aVal != "hidden") return -1;
          return isDesc ? bVal - aVal : aVal - bVal;
        });
      }

      return list;
    },
    changeTableSort(column, defaultDir = "desc") {
      if (column !== this.tableSort.col) {
        this.tableSort.col = column;
        this.tableSort.dir = defaultDir;
      } else {
        this.tableSort.dir = this.tableSort.dir === "asc" ? "desc" : "asc";
      }
    },
  };
}
