import { pluralize } from "@/lib/utils";
import { eqArrays, eqSets } from "../../shared/utils";

export function squadMultiselect(args) {
  const { onChange, treatAllAsNone } = args;

  const selectedIds = new Set(args.selectedIds);

  let originalValue, squads, rootIds;

  return {
    selectedIds,
    disabledIds: [],
    dynamicPrompt: null,
    open: false,
    init() {
      this.$root.addEventListener("dx:squadsTree:init", (e) => {
        squads = e.detail;

        rootIds = new Set(squads.filter((s) => s.depth == 0).map((s) => s.id));

        this.disabledIds = initialDisabledIds(selectedIds, squads);

        this.setPrompt();
      });
    },
    get selectedIdsArr() {
      return Array.from(this.selectedIds);
    },
    toggleDropdown() {
      if (this.open) {
        return this.unfocus();
      }

      this.focus();
    },
    focus() {
      this.open = true;

      originalValue = this.selectedIdsArr;

      const searchField = this.$root.querySelector(".x-searchInput");

      this.$nextTick(() => {
        searchField.focus();
      });
    },
    unfocus() {
      if (!this.open) return;

      this.open = false;

      const currentValue = this.selectedIdsArr;
      const changed = !eqArrays(originalValue, currentValue);
      const allSelected = eqSets(this.selectedIds, rootIds);

      this.$nextTick(() => {
        if (changed) {
          if (allSelected && treatAllAsNone) {
            onChange(this.$root, []);
          } else {
            onChange(this.$root, Array.from(currentValue));
          }
        }
      });
    },
    setPrompt() {
      if (eqSets(this.selectedIds, rootIds)) {
        this.dynamicPrompt = "All teams";
      } else if (this.selectedIds.size > 0) {
        const count = countLeaves(this.selectedIds, squads);

        this.dynamicPrompt = pluralize("team", count);
      } else {
        this.dynamicPrompt = null;
      }
    },
    selectId(id) {
      this.selectedIds.add(id);
    },
    deselectId(id) {
      this.selectedIds.delete(id);
    },
    isSelected(id) {
      return this.selectedIds.has(id);
    },
    disableId(id) {
      this.disabledIds.add(id);
    },
    undisableId(id) {
      this.disabledIds.delete(id);
    },
    isDisabled(id) {
      return this.disabledIds.has(id);
    },
    selectSquad(id) {
      this.selectId(id);

      // Disable and de-select children
      getChildIds(id, squads).forEach((cid) => {
        this.disableId(cid);
        this.deselectId(cid);
      });
    },
    deselectSquad(id) {
      this.deselectId(id);

      // Un-disable children
      getChildIds(id, squads).forEach((cid) => this.undisableId(cid));
    },
    toggleSelectSquad(id) {
      if (this.isDisabled(id)) return;

      if (this.isSelected(id)) {
        this.deselectSquad(id);
      } else {
        this.selectSquad(id);
      }

      this.setPrompt();
    },
    selectAll() {
      squads.forEach((squad) => {
        if (squad.depth == 0) {
          this.selectSquad(squad.id);
        }
      });

      this.setPrompt();
    },
    deselectAll() {
      this.selectedIds.clear();

      this.disabledIds.clear();

      this.setPrompt();
    },
  };
}

function getChildIds(parentId, squads) {
  const childIds = [];

  let parent = null;

  squads.forEach((squad) => {
    if (parentId == squad.id) {
      parent = squad;
      return;
    }

    if (parent && parent.depth < squad.depth) {
      childIds.push(squad.id);
    }

    if (parent && parent.depth >= squad.depth) {
      parent = null;
    }
  });

  return childIds;
}

function initialDisabledIds(selectedIds, squads) {
  const disabledIds = new Set();

  let parent = null;

  squads.forEach((squad) => {
    if (selectedIds.has(squad.id)) {
      parent = squad;
      return;
    }

    if (parent && parent.depth < squad.depth) {
      disabledIds.add(squad.id);
    }

    if (parent && parent.depth >= squad.depth) {
      parent = null;
    }
  });

  return disabledIds;
}

function countLeaves(selectedIds, squads) {
  let count = 0;
  let parent = null;

  squads.forEach((squad) => {
    if (selectedIds.has(squad.id)) {
      if (!squad.is_parent) count++;

      parent = squad;

      return;
    }

    if (parent && parent.depth < squad.depth && !squad.is_parent) {
      count++;
    }

    if (parent && parent.depth >= squad.depth) {
      parent = null;
    }
  });

  return count;
}
