import { getRecursiveChildren, getDirectChildren } from "../shared/tree";
import { setLocalStorageItem } from "../shared/utils";

const iconExpanded = "bx-chevron-down";
const iconCollapsed = "bx-chevron-right";

const { currentUser } = window;
const storageKey = `heatmap_${currentUser?.account_id}_v2`;

let expandedSquadIds = {};

const makeCollapsed = (el) => {
  // Change caret icon
  const caret = el.querySelector(`.${iconExpanded}`);

  if (caret) {
    caret.classList.remove(iconExpanded);
    caret.classList.add(iconCollapsed);
  }

  // Mark as collapsed
  el.classList.remove("x-expanded");
};

const makeExpanded = (el) => {
  // Change caret icon
  const caret = el.querySelector(`.${iconCollapsed}`);

  if (caret) {
    caret.classList.remove(iconCollapsed);
    caret.classList.add(iconExpanded);
  }

  // Mark as expanded
  el.classList.add("x-expanded");
};

const setDefaultExpanded = () => {
  expandedSquadIds = JSON.parse(localStorage.getItem(storageKey) || "{}");

  // Expand based on localStorage
  const rows = document.querySelectorAll(
    ".x-heatmap-row.x-parent:not(.x-expanded)",
  );

  rows.forEach((el) => {
    if (expandedSquadIds[el.dataset.id] !== undefined) {
      toggleHeatmapAccordion(el);
    }
  });

  // If there is one root and it is a parent, expand it
  const roots = document.querySelectorAll(".x-heatmap-row:not(.hidden)");

  if (roots.length == 1 && roots[0].classList.contains("x-parent")) {
    getDirectChildren(roots[0]).forEach((el) => {
      el.classList.remove("hidden");
    });

    makeExpanded(roots[0]);

    return;
  }
};

function toggleHeatmapAccordion(e) {
  const root = e.closest("tr");

  // Behavior for when accordion is expanded
  if (root.classList.contains("x-expanded")) {
    // Hide and collapse children
    getRecursiveChildren(root).forEach((el) => {
      el.classList.add("hidden");

      makeCollapsed(el);
      // Remove from localStorage
      delete expandedSquadIds[el.dataset.id];
      setLocalStorageItem(storageKey, JSON.stringify(expandedSquadIds));
    });

    makeCollapsed(root);
    // Remove from localStorage
    delete expandedSquadIds[root.dataset.id];
    setLocalStorageItem(storageKey, JSON.stringify(expandedSquadIds));

    return;
  }

  // Show children
  getDirectChildren(root).forEach((el) => {
    el.classList.remove("hidden");
  });

  makeExpanded(root);
  // Add to localStorage
  expandedSquadIds[root.dataset.id] = true;
  setLocalStorageItem(storageKey, JSON.stringify(expandedSquadIds));
}

window.toggleHeatmapAccordion = toggleHeatmapAccordion;

document.addEventListener("turbo:load", () => setDefaultExpanded());
document.addEventListener("dx:heatmap:loaded", () => setDefaultExpanded());
