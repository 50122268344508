import { LineChart, StackedAreaChart } from "@get-dx/d3-charts";
import {
  getQueryStringParams,
  setQueryStringParam,
  deleteQueryStringParam,
  objectToQueryString,
  generatePreviousComparisonValues,
  generateBenchmarkComparisonValues,
  sortFunc,
  numberToSpelling,
  snapshotSquadsFromCache,
  generateOrgComparisonValues,
} from "../../shared/utils";
import { chartTooltipHtml } from "../chartTooltipHtml";

const trendOptionsMap = new Map([
  ["vs_prev", "prev_percentage"],
  ["vs_org", "org_percentage"],
  ["vs_50th", "benchmark_50"],
  ["vs_75th", "benchmark_75"],
  ["vs_90th", "benchmark_90"],
]);

function generateComparisonValues(
  resource,
  benchmark,
  resourceType = "factor",
) {
  let comparisonValues = [];

  if (
    ["vs_prev", "prev_percentage"].includes(benchmark) &&
    resource.values.length > 1
  ) {
    comparisonValues = generatePreviousComparisonValues(resource);
  } else if (!["vs_prev", "prev_percentage"].includes(benchmark)) {
    comparisonValues = generateBenchmarkComparisonValues(
      resource,
      resourceType,
      benchmark,
    );
  }

  return comparisonValues;
}

export function snapshotDelivery(defaults) {
  const {
    baseUrl,
    drilldownTabs,
    kpiScores,
    showOrgAvg,
    minResponseThreshold,
    tagOptions,
    snapshotId,
    hasHierarchyGroups,
  } = defaults;
  const targetedKpiId = getQueryStringParams().get("factor_id");
  const selectedKpi = targetedKpiId
    ? kpiScores
        .filter((i) => i.current_snapshot)
        .find((score) => score.id == targetedKpiId)
    : null;

  return {
    kpiScores,
    drilldownTabs,
    drilldownData: {},
    drilldownModalOpen: !!selectedKpi,
    selectedKpi,
    selectedDrilldownTab: getQueryStringParams().get("ddt"),
    loadingTabData: false,
    trends: null,
    xStart: null,
    maxKpiScore: null,
    xEnd: null,
    squadNamesFromSquadIds: [],
    showTeamBreakdown: true,
    init() {
      this.$root.addEventListener("dx:squadsTree:init", () => {
        this.determineSquadNamesFromSquadIds();
        // Loading directly to modal from URL cached squads are empty, so wait for event
        if (this.squadIds.length != 1) {
          this.showTeamBreakdown = true;
        } else {
          const matchedSquad = this.cachedSquads.find(
            (squad) => squad.id == this.squadIds[0],
          );
          this.showTeamBreakdown = matchedSquad?.is_parent;
        }
      });

      this.fetchAll().then(() => {
        if (this.selectedDrilldownTab) {
          const selectedItem = drilldownTabs.find(
            (t) => t.tab == this.selectedDrilldownTab,
          );
          this.fetchTab(this.selectedDrilldownTab, selectedItem.url);
        }
      });

      this.$store.snapshotResultFilters.hasHierarchyGroups = hasHierarchyGroups;
    },
    get cachedSquads() {
      return snapshotSquadsFromCache(snapshotId);
    },
    get filtersApplied() {
      return (
        this.$store.snapshotResultFilters.tagIds.length ||
        this.$store.snapshotResultFilters.squadIds.length ||
        this.$store.snapshotResultFilters.hierarchyGroup
      );
    },
    determineSquadNamesFromSquadIds() {
      const selectedSquads = this.cachedSquads.filter(
        (squad) =>
          this.squadIds.includes(String(squad.id)) ||
          this.squadIds.includes(squad.id),
      );

      this.squadNamesFromSquadIds = selectedSquads.map((squad) => squad.name);
      // Loading directly to modal from URL cached squads are empty, so wait for event
      if (this.squadIds.length != 1) {
        this.showTeamBreakdown = true;
      } else {
        this.showTeamBreakdown = this.cachedSquads.find(
          (squad) => squad.id == this.squadIds[0],
        )?.is_parent;
      }
    },
    get showOrgComparision() {
      return (
        showOrgAvg && this.$store.snapshotResultFilters.squadIds.length > 0
      );
    },
    get kpiItems() {
      const items = this.kpiScores.filter((i) => i.current_snapshot);

      return items;
    },
    get compareTo() {
      return this.$store.snapshotResultFilters.compareTo;
    },
    get previousSnapshotId() {
      return this.$store.snapshotResultFilters.previousSnapshotId;
    },
    get selectedKpiComparison() {
      const rowData = this.selectedKpi;

      if (!rowData) return null;

      return rowData[this.compareTo];
    },
    get tagIds() {
      return this.$store.snapshotResultFilters.tagIds;
    },
    get squadIds() {
      return this.$store.snapshotResultFilters.squadIds;
    },
    get drilldownSortBy() {
      return this.$store.snapshotResultFilters.drilldownSortBy;
    },
    get drilldownSortReverse() {
      return this.$store.snapshotResultFilters.drilldownSortReverse;
    },
    get hasMultipleTrendValues() {
      return this?.trends?.values?.length > 1;
    },
    get visibleDrilldownTabs() {
      let filteredTabs = drilldownTabs.filter((drilldownTab) => {
        if (drilldownTab.tab == "team_breakdown") return this.showTeamBreakdown;

        return true;
      });

      if (hasHierarchyGroups) {
        filteredTabs = filteredTabs.map((t) =>
          t.tab == "team_breakdown" ? { ...t, text: "Breakdown" } : t,
        );
      }

      return filteredTabs;
    },
    get tagNamesFromTagIds() {
      const selectedTags = tagOptions.filter(
        (tag) =>
          this.tagIds.includes(String(tag.id)) || this.tagIds.includes(tag.id),
      );

      return selectedTags.map((tag) => tag.name);
    },
    kpiName(item) {
      if (!item?.reliability) return item?.name;

      if (window.currentAccount?.feature_flags?.use_reliability_label)
        return "Reliability";

      return item.name;
    },
    kpiDescription(item) {
      if (item.speed) {
        return "Speed of delivery.";
      }
      if (item.name.toUpperCase() == "Ease of Delivery".toUpperCase()) {
        return "Ease of being a technical contributor.";
      }
      if (item.reliability) {
        return "Technical stability of systems.";
      }
      if (item.time_loss) {
        return "Time lost due to inefficiencies.";
      }
      if (item.name == "Engagement") {
        return "Enthusiasm towards work.";
      }
      if (item.name.toUpperCase() == "Perceived Productivity".toUpperCase()) {
        return "Self-reported productivity.";
      }
    },
    openDrilldownModal(item) {
      // Don't show any modals if not enough responses
      if (this.responseCount < minResponseThreshold) return;

      this.drilldownModalOpen = true;
      this.selectedKpi = item;
      this.selectedDrilldownTab = "overview";

      setQueryStringParam("factor_id", item.id);
      setQueryStringParam("ddt", "overview");
      this.fetchTab(this.drilldownTabs[0].tab, this.drilldownTabs[0].url);
    },
    hideDeliveryDetails() {
      this.drilldownModalOpen = false;
      this.targetedKpi = null;
      this.selectedDrilldownTab = null;
      // Clear the data so we don't run into weird cache issues.
      this.drilldownData = {};
      this.trends = null;

      deleteQueryStringParam("ddt");
      deleteQueryStringParam("factor_id");
    },
    showDistributionLabel(item, position) {
      let label;

      if (item.reliability || item.speed) {
        label = this.showOptionLabel(JSON.parse(item.options)[position - 1]);
      } else if (item.time_loss) {
        label = this.showOptionLabel(JSON.parse(item.options)[position]);
      } else {
        switch (position) {
          case 1:
            label = this.showOptionLabel(JSON.parse(item.options)[4]);
            break;
          case 2:
            label = this.showOptionLabel(JSON.parse(item.options)[3]);
            break;
          case 3:
            label = this.showOptionLabel(JSON.parse(item.options)[2]);
            break;
          case 4:
            label = this.showOptionLabel(JSON.parse(item.options)[1]);
            break;
          case 5:
            label = this.showOptionLabel(JSON.parse(item.options)[0]);
            break;
        }
      }

      return label;
    },
    showOptionLabel(optionLabel) {
      if (typeof optionLabel == "object") return optionLabel[0];

      return optionLabel;
    },
    calculateItemComparison(item) {
      if (this.compareTo == "vs_50th") {
        return item.score - item.benchmark_50;
      } else if (this.compareTo == "vs_75th") {
        return item.score - item.benchmark_75;
      } else if (this.compareTo == "vs_90th") {
        return item.score - item.benchmark_90;
      } else if (this.compareTo == "vs_prev") {
        return item.previous_score != null
          ? item.score - item.previous_score
          : null;
      } else if (this.compareTo == "vs_org") {
        return item.org_score != null
          ? item.score - item.org_score
          : item.score - this.selectedKpi?.org_score;
      }
    },
    percentageOfResponsesOverview(positionCount, totalFieldCount) {
      const item = this.selectedKpi;

      if (!item) return;

      if (item[totalFieldCount] === 0) return "0%";

      return `${Math.round(
        (100 * item[positionCount]) / item[totalFieldCount],
      )}%`;
    },
    fetchTab(tab, url) {
      if (!this.drilldownData[tab]) {
        this.loadingTabData = true;
      }
      // Make this smarter so if we've already fetched the tab for the SAME factor we don't fetch it again
      this.selectedDrilldownTab = tab;
      setQueryStringParam("ddt", tab);

      return fetch(`${url}${this.queryParams()}`)
        .then((resp) => resp.json())
        .then((data) => {
          this.drilldownData[tab] = { data };
          this.loadingTabData = false;
          this.renderTabDetails(tab, data);
        });
    },
    queryParams() {
      const paramObj = {
        ct: this.compareTo,
        prev_ss: this.previousSnapshotId,
        squad_ids: `${this.squadIds.join(",")}`,
        tag_ids: `${this.tagIds.join(",")}`,
        factor_id: this.selectedKpi?.id,
        hg: this.$store.snapshotResultFilters.hierarchyGroup.encoded_id,
        branch: this.$store.snapshotResultFilters.branch,
      };

      return `?${objectToQueryString(paramObj)}`;
    },
    fetchAll() {
      this.refetchTeamBreakdown();

      return this.fetchResultItems();
    },
    fetchResultItems() {
      const paramsObj = {
        ct: this.compareTo,
        prev_ss: this.previousSnapshotId,
        squad_ids: this.squadIds.join(","),
        tag_ids: this.tagIds.join(","),
        branch: this.$store.snapshotResultFilters.branch,
      };

      const queryParams = objectToQueryString(paramsObj);

      return fetch(`${baseUrl}?${queryParams}`)
        .then((resp) => resp.json())
        .then((data) => {
          this.kpiScores = data.kpi_scores;

          if (this.selectedKpi) {
            const newKpi = this.kpiScores
              .reverse()
              .find((obj) => obj.id == this.selectedKpi.id);

            const selectedKpiToSet = {
              ...this.selectedKpi,
              prev_score: newKpi.prev_score,
              previous_score: newKpi.previous_score,
              vs_prev: newKpi.score - newKpi.prev_score,
            };

            this.selectedKpi = selectedKpiToSet;
          }
        });
    },
    renderTabDetails(tab, data) {
      if (tab == "overview") {
        // We get all of the outcome trends in one query so we can reduce queries in the future
        this.trends = data.trends.find(
          (trend) => trend.factor.id == this.selectedKpi?.id,
        );
        this.xStart = data.x_start;
        this.renderDriverTrendChart();
        this.renderDriverDistributionChart();
      }
      if (tab == "team_breakdown") {
        this.calculateMaxTeamScore();
      }
    },
    calculateMaxTeamScore() {
      const arr = this.breakdownItems.map((i) => i.score);

      this.maxKpiScore = Math.max(...arr);
    },
    percentageOfResponses(item, positionCount) {
      if (item.count === 0) return "0%";

      return `${Math.round((100 * item[positionCount]) / item.count)}%`;
    },
    xStartLabel() {
      const date = new Date(this.xStart);
      const month = date.toLocaleString("default", { month: "short" });
      const year = date.getFullYear();

      return `${month} ${year}`;
    },
    renderDriverTrendChart() {
      const elChart = document.getElementById(`kpi-trends-chart`);
      if (!elChart) return;

      elChart.innerHTML = null;
      const benchmark = this.compareTo;
      const minVal = Math.min(
        ...this.trends.values
          .map((v) => v.value)
          .concat(this.trends.factor.benchmark_50),
      );
      const maxVal = Math.max(
        ...this.trends.values
          .map((v) => v.value)
          .concat(this.trends.factor.benchmark_90),
      );

      const trendSize = this.trends.values.length;
      this.xEnd = this.trends.values[trendSize - 1]?.label;
      // Avoid using 'this' in passing in function for the tooltip callback, get the string explicitly
      const heading = this.kpiName(this.selectedKpi);
      const timeLoss = this.selectedKpi.time_loss;

      const attrs = {
        elChart: elChart,
        startDate: this.xStart,
        endDate: this.trends.values[trendSize - 1]?.date,
        tooltipHtml(d, cd) {
          const params = {
            heading: heading,
            d: {
              label: d.label,
              value: d.value,
              formattedValue: d.value,
            },
          };

          if (cd) {
            params.cd = {
              label: cd.label,
              value: cd.value,
              formattedValue: cd.value,
            };
          }

          return timeLoss
            ? chartTooltipHtml.timeLoss(params)
            : chartTooltipHtml.default(params);
        },
        yAxisMin: minVal * 0.8,
        yAxisMax: maxVal * 1.2,
        showXAxisTicks: false,
        values: this.trends.values,
      };

      if (
        benchmark &&
        (this.trends.factor[trendOptionsMap.get(benchmark)] ||
          benchmark === "vs_prev")
      ) {
        attrs.comparisonValues = generateComparisonValues(
          this.trends,
          trendOptionsMap.get(benchmark),
        );
      }

      if (benchmark == "vs_org") {
        attrs.comparisonValues = generateOrgComparisonValues(
          this.trends,
          Math.round(this.selectedKpi?.org_score),
        );
      }

      return new LineChart(attrs);
    },
    renderDriverDistributionChart() {
      if (!this.hasMultipleTrendValues) return;
      const elChart = document.getElementById(`kpi-distribution-chart`);
      if (!elChart) return;

      elChart.innerHTML = null;
      const benchmark = this.compareTo;
      const minVal = Math.min(
        ...this.trends.values
          .map((v) => v.value)
          .concat(this.trends.factor.vs_50th),
      );
      const maxVal = Math.max(
        ...this.trends.values
          .map((v) => v.value)
          .concat(this.trends.factor.vs_90th),
      );

      const trendSize = this.trends.values.length;
      this.xEnd = this.trends.values[trendSize - 1]?.label;
      // Avoid using 'this' in passing in function for the tooltip callback, get the string explicitly

      const colors = [
        "#D2B6F9",
        "#A8B4F7",
        "#92D1F8",
        "#86E7D5",
        "#A0EDB2",
        "#BEF264",
      ];
      let stackedAreaValues = { series: [], dates: [] };
      let options = JSON.parse(this.selectedKpi?.options);

      // NOTE: 'Ease of delivery', 'Engagement', 'Perceived Productivity' direction reversing
      const kpisToReverse = [330, 329, 328];
      if (kpisToReverse.includes(this.selectedKpi.id))
        options = options.reverse();

      options.forEach((opt, idx) => {
        stackedAreaValues.series.push({
          name: opt,
          color: colors[idx],
          counts: [],
          key: `${options.length < 6 ? numberToSpelling(idx + 1) : numberToSpelling(idx)}_count`,
          // NOTE: Differing key access for options w/ 6 values
        });
      });

      // Formatting the trend data to match the required format the stacked area chart needs
      this.trends.values.forEach((val) => {
        stackedAreaValues.dates.push(val.date);

        stackedAreaValues.series.forEach((breakdownValue) => {
          breakdownValue.counts.push(val.breakdown[breakdownValue.key]);
        });
      });

      // NOTE: reversing here so that the stacks matches the order of the distribution chart above
      stackedAreaValues.series = stackedAreaValues.series.reverse();

      const attrs = {
        elChart: elChart,
        startDate: this.xStart,
        endDate: this.trends.values[trendSize - 1]?.date,
        tooltipHtml(d, cd) {
          const params = {
            heading: new Intl.DateTimeFormat("en-US", {
              month: "short",
              year: "numeric",
            }).format(d),
            rows: cd
              .map((r) => ({
                label: r.name,
                value: r.count,
                formattedValue: Math.round(r.percentage * 100) + "%",
                color: r.color,
              }))
              .reverse(),
          };

          return chartTooltipHtml.default(params);
        },
        yAxisMin: minVal * 0.8,
        yAxisMax: maxVal * 1.2,
        showXAxisTicks: false,
        values: stackedAreaValues,
      };

      if (
        benchmark &&
        (this.trends.factor[benchmark] || benchmark === "vs_prev")
      ) {
        attrs.comparisonValues = generateComparisonValues(
          this.trends,
          benchmark,
        );
      }

      return new StackedAreaChart(attrs);
    },
    get breakdownItems() {
      if (this.drilldownData.team_breakdown) {
        const items = this.drilldownData.team_breakdown.data.result_items.sort(
          sortFunc(this.drilldownSortBy, this.compareTo, {
            orgScore: this.selectedKpi.org_score,
            reverse: this.drilldownSortReverse,
          }),
        );

        const fieldsWithReversibleSortFunctions = [
          "score",
          "calculatedComparison",
          "dxiComparison",
        ];

        if (
          !fieldsWithReversibleSortFunctions.includes(this.drilldownSortBy) &&
          this.drilldownSortReverse
        ) {
          return items.reverse();
        }

        return items;
      }

      return [];
    },
    isDrilldownSortOrder(order) {
      return this.drilldownSortBy == order;
    },
    changeComparison(compareTo) {
      this.$store.snapshotResultFilters.setCompareTo(compareTo);
      if (this.compareTo == "vs_prev") this.fetchAll();
      // Update any trends comparison data if it's showing
      if (this.selectedDrilldownTab == "overview") {
        this.renderDriverTrendChart();
        this.renderDriverDistributionChart();
      }
    },
    changeSortBy(sortBy) {
      if (this.sortBy == sortBy) {
        this.$store.snapshotResultFilters.sortReverse = !this.sortReverse;

        setQueryStringParam("sr", this.sortReverse);
        return;
      }

      this.$store.snapshotResultFilters.sortBy = sortBy;
      this.$store.snapshotResultFilters.sortReverse = false;

      setQueryStringParam("sb", sortBy);
      setQueryStringParam("sr", false);
    },
    changeDrilldownSortBy(sortBy) {
      if (this.drilldownSortBy == sortBy) {
        this.$store.snapshotResultFilters.drilldownSortReverse =
          !this.drilldownSortReverse;

        setQueryStringParam("ddsr", this.drilldownSortReverse);
        return;
      }

      this.$store.snapshotResultFilters.drilldownSortBy = sortBy;
      this.$store.snapshotResultFilters.drilldownSortReverse = false;

      setQueryStringParam("ddsb", sortBy);
      setQueryStringParam("ddsr", false);
    },
    changeSquadIds(squadIds) {
      this.$store.snapshotResultFilters.squadIds = squadIds;
      this.determineSquadNamesFromSquadIds();
      setQueryStringParam("squad_ids", squadIds);

      // Org comparison should only be allowed to be set when there are filters applied
      if (
        this.squadIds.length == 0 &&
        this.tagIds.length == 0 &&
        this.compareTo == "vs_org"
      ) {
        setQueryStringParam("ct", null);
      }

      this.fetchAll();
    },
    changeTagIds(tagIds) {
      this.$store.snapshotResultFilters.tagIds = tagIds;
      setQueryStringParam("tag_ids", tagIds);

      // Org comparison should only be allowed to be set when there are filters applied
      if (
        this.squadIds.length == 0 &&
        this.tagIds.length == 0 &&
        this.compareTo == "vs_org"
      ) {
        setQueryStringParam("ct", null);
      }

      this.fetchAll();
    },
    refetchTeamBreakdown() {
      const selectedItem = drilldownTabs.find(
        (t) => t.tab == this.selectedDrilldownTab,
      );

      if (selectedItem) {
        this.fetchTab(this.selectedDrilldownTab, selectedItem.url);
      }
    },
  };
}
