import { LineChart } from "@get-dx/d3-charts";
import {
  addCurrentPageEventListener,
  generateBenchmarkComparisonValues,
  generatePreviousComparisonValues,
} from "../../shared/utils";
import { chartTooltipHtml } from "../chartTooltipHtml";

function generateComparisonValues(behavioralQuestion, benchmark) {
  let comparisonValues = [];

  if (benchmark === "previous_mark" && behavioralQuestion.values.length > 1) {
    comparisonValues = generatePreviousComparisonValues(behavioralQuestion);
  } else if (benchmark !== "previous_mark") {
    comparisonValues = generateBenchmarkComparisonValues(
      behavioralQuestion,
      "workflow",
      benchmark,
    );
  }

  return comparisonValues;
}

export function insightsWorkflows(args) {
  const { data, xStart, xEnd } = args;

  const charts = data.map((behavioralQuestion) => {
    const benchmark = localStorage.getItem("trendCompareTo") || "previous_mark";
    const minVal = Math.min(
      ...behavioralQuestion.values
        .map((v) => v.value)
        .concat(behavioralQuestion.workflow.benchmark_50),
    );
    const maxVal = Math.max(
      ...behavioralQuestion.values
        .map((v) => v.value)
        .concat(behavioralQuestion.workflow.benchmark_90),
    );

    const attrs = {
      elChart: document.getElementById(
        `workflow-${behavioralQuestion.workflow.id}-chart`,
      ),
      startDate: xStart,
      endDate: xEnd,
      tooltipHtml(d, cd) {
        const params = {
          heading: behavioralQuestion.workflow.name,
          d: {
            label: d.label,
            value: d.value,
            formattedValue: `${d.value}%`,
          },
        };

        if (cd) {
          params.cd = {
            label: cd.label,
            value: cd.value,
            formattedValue: `${cd.value}%`,
          };
        }

        return chartTooltipHtml.default(params);
      },
      yAxisMin: minVal * 0.8,
      yAxisMax: maxVal * 1.2,
      showXAxisTicks: false,
      values: behavioralQuestion.values,
    };

    if (
      benchmark &&
      (behavioralQuestion.workflow[benchmark] || benchmark === "previous_mark")
    ) {
      attrs.comparisonValues = generateComparisonValues(
        behavioralQuestion,
        benchmark,
      );
    }

    return new LineChart(attrs);
  });

  return {
    charts,
    init() {
      addCurrentPageEventListener("dx:compareTo-change", (event) => {
        this.charts.forEach((chart, index) => {
          const behavioralQuestion = data[index];

          if (
            behavioralQuestion.workflow[event.detail.benchmark] ||
            event.detail.benchmark === "previous_mark"
          ) {
            const comparisonValues = generateComparisonValues(
              behavioralQuestion,
              event.detail.benchmark,
            );
            chart.comparisonValues = comparisonValues;
            chart.redraw();
          } else {
            chart.comparisonValues = [];
            chart.redraw();
          }
        });
      });
    },
  };
}
