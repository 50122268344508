import {
  addCurrentPageEventListener,
  objectToQueryString,
  fetchWithWait,
  generatePreviousComparisonValues,
} from "../../shared/utils";

export function datacloudAsyncCharts(args) {
  const { chartNames, endpoint, getFilters } = args;

  const teamsFilter = getFilters().teams ?? [];

  return {
    chartNames,
    endpoint,
    variants: {},
    chartsData: {},
    charts: {},
    sqlQueries: {},
    rawSql: "",
    sqlModalChartTitle: "",
    showQueryModal: false,
    hasTeamsFilter: teamsFilter.length !== 0,
    init() {
      this.chartNames.forEach(
        (chart) => (this.chartsData[chart] = { loading: true }),
      );

      // $nextTick is required so that the spinner displays itself
      this.$nextTick(() => {
        this.reloadCharts();
      });

      addCurrentPageEventListener("dx:datacloud-filters-changed", () => {
        const teamsFilter = getFilters().teams ?? [];

        this.hasTeamsFilter = teamsFilter.length !== 0;
        this.reloadCharts();
      });
    },
    setVariant(chart, variant) {
      this.variants[chart] = variant;

      this.reloadChart(chart);
    },
    currentVariant(chart) {
      return this.variants[chart];
    },
    reloadCharts: function () {
      this.chartNames.forEach((chart) => this.reloadChart(chart));
    },
    reloadChart: function (chartName) {
      const filtersQueryString = objectToQueryString(getFilters());
      const variant = this.variants[chartName];
      this.chartsData[chartName].loading = true;
      fetchWithWait(
        `${this.endpoint}/${chartName}${
          "?" + filtersQueryString + (variant ? "&variant=" + variant : "")
        }`,
      )
        .then((data) => {
          this.chartsData[chartName] = data.chart;
          this.sqlQueries[chartName] = data.sql;
        })
        .then(() => this.loadChart(chartName))
        .catch(() => {
          // no op
          this.chartsData[chartName].displayable = true; // this will display 'no data'
        });
    },
    loadChart: function (chartName) {
      if (this.chartsData[chartName]) {
        if (this.charts[chartName]) {
          const values = this.chartsData[chartName].data;
          const comparisonValues = generatePreviousComparisonValues({ values });

          this.charts[chartName].values = this.chartsData[chartName].data;
          this.charts[chartName].comparisonValues = comparisonValues;
          this.charts[chartName].startDate =
            this.chartsData[chartName].startDate;
          this.charts[chartName].endDate = this.chartsData[chartName].endDate;
          this.charts[chartName].yAxisMin = this.charts[chartName].values
            ? Math.min(...this.charts[chartName].values.map((v) => v.value)) *
              0.8
            : 0;
          this.charts[chartName].yAxisMax = this.charts[chartName].values
            ? Math.max(...this.charts[chartName].values.map((v) => v.value)) *
              1.2
            : 100;
          this.charts[chartName].dotLastSegment =
            this.chartsData[chartName].dotLastSegment;
          this.charts[chartName].redraw();
        } else {
          this.chartsData[chartName].elChart = document.getElementById(
            this.chartsData[chartName].chartId,
          );
          this.chartsData[chartName].yAxisMin = this.chartsData[chartName]
            .values
            ? Math.min(
                ...this.chartsData[chartName].values.map((v) => v.value),
              ) * 0.8
            : 0;
          this.chartsData[chartName].yAxisMax = this.chartsData[chartName]
            .values
            ? Math.max(
                ...this.chartsData[chartName].values.map((v) => v.value),
              ) * 1.2
            : 100;
          this.charts[chartName] = new window.LineChart(
            this.chartsData[chartName],
          );
          this.charts[chartName].redraw();
        }
        this.chartsData[chartName].loading = false;
      }
    },
    initChart(chartName, chartData, sql) {
      this.chartsData[chartName] = chartData;
      this.loadChart(chartName);
      this.sqlQueries[chartName] = sql;
    },
    showSql(chartName) {
      this.rawSql = this.sqlQueries[chartName];
      this.sqlModalChartTitle = this.chartsData[chartName].title;
      this.showQueryModal = true;
    },
  };
}
