import { LineChart } from "@get-dx/d3-charts";
import {
  setQueryStringParam,
  deleteQueryStringParam,
  objectToQueryString,
  sortFunc,
} from "../../shared/utils";
import { chartTooltipHtml } from "../chartTooltipHtml";

export function snapshotResponseCountDetails(url) {
  return {
    responseCountTabs: [{ tab: "Current snapshot" }, { tab: "Trend" }],
    selectedResponseCountTab: "Current snapshot",
    snapshotRequests: [],
    trendData: {},
    openModal: false,
    resultsLoaded: false,
    get drilldownSortBy() {
      return this.$store.snapshotResultFilters.drilldownSortBy;
    },
    get drilldownSortReverse() {
      return this.$store.snapshotResultFilters.drilldownSortReverse;
    },
    hide() {
      deleteQueryStringParam("ddsb");
      deleteQueryStringParam("ddsr");
      this.openModal = false;
    },
    fetchDetails() {
      return fetch(`${url}${this.queryParams()}`)
        .then((resp) => resp.json())
        .then((data) => {
          this.snapshotRequests = data.snapshot_requests;
          this.trendData = data.trends;
          this.resultsLoaded = true;
        });
    },
    setTab(tab) {
      this.selectedResponseCountTab = tab;
      if (tab == "Trend") setTimeout(() => this.buildTrendChart(), 50);
    },
    queryParams() {
      const paramObj = {
        squad_ids: `${this.squadIds.join(",")}`,
        tag_ids: `${this.tagIds.join(",")}`,
        factor_id: this.targetedFactor,
        branch: this.$store.snapshotResultFilters.branch,
      };

      return `?${objectToQueryString(paramObj)}`;
    },
    get orderedItems() {
      const items = this.snapshotRequests.sort(sortFunc(this.drilldownSortBy));

      if (this.drilldownSortReverse) {
        return items.reverse();
      }

      return items;
    },
    changeResponseDetailSortBy(sortBy) {
      if (this.drilldownSortBy == sortBy) {
        this.$store.snapshotResultFilters.drilldownSortReverse =
          !this.drilldownSortReverse;

        setQueryStringParam("ddsr", this.drilldownSortReverse);
        return;
      }

      this.$store.snapshotResultFilters.drilldownSortBy = sortBy;
      this.$store.snapshotResultFilters.drilldownSortReverse = false;

      setQueryStringParam("ddsb", sortBy);
      setQueryStringParam("ddsr", false);
    },
    buildTrendChart() {
      const chartData = this.trendData;
      const chartMax = Math.max(...chartData.map((obj) => obj.value)) || 0;
      const elChart = document.getElementById(`response-count-trends-chart`);
      elChart.innerHTML = null;

      if (chartData.length > 1) {
        const chartParams = {
          elChart,
          startDate: chartData[0].date,
          endDate: chartData[chartData.length - 1].date,
          yAxisMin: 0,
          yAxisMax: Math.round(chartMax * 1.2),
          tooltipHtml(d) {
            const params = {
              heading: "Participation rate",
              d: {
                label: `Snapshot: ${d.label}`,
                value: d.value,
                formattedValue: `${d.value}%`,
              },
            };

            return chartTooltipHtml.participationTrend(params);
          },
          values: chartData,
        };

        new LineChart(chartParams);
      }
    },
  };
}
