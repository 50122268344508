export function notifications(args) {
  const { notificationsUrl, unreadCount } = args;

  let _initialLoadingCompleted = false;

  const initialState = {
    unreadCount,
    visible: false,
    loading: false,
    cursor: undefined,
    notifications: [],
  };

  return {
    ...initialState,
    fetchNotifications() {
      let url = notificationsUrl;

      if (this.cursor) {
        url += `?cursor=${this.cursor}`;
      }

      this.loading = true;

      this.unreadCount = 0;

      fetch(url)
        .then((response) => response.json())
        .then((data) => {
          this.notifications.push.apply(this.notifications, data.notifications);

          this.cursor = data.cursor;

          this.loading = false;
        });
    },
    showNotifications() {
      this.visible = true;

      if (!_initialLoadingCompleted) {
        this.fetchNotifications();

        _initialLoadingCompleted = true;
      }
    },
    notificationSubtext(notification) {
      if (notification.name == "changelog") {
        return "Learn more →";
      }

      return `${notification.time_ago} ago`;
    },
    hideNotifications() {
      this.visible = false;
    },
    handleScroll() {
      const { scrollTop, offsetHeight, scrollHeight } =
        this.$refs.scrollContainer;

      const fetchMore = scrollTop + offsetHeight + 20 > scrollHeight;

      if (fetchMore && !this.loading && this.cursor) {
        this.fetchNotifications();
      }
    },
  };
}
