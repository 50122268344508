const teamObj = { name: "Team" };

export function snapshotHierarchyGroups(args) {
  return {
    expanded: false,
    aggregationOptions: args?.aggregationOptions?.map((o) => ({
      ...o,
      name: o.label,
    })),
    groups: [],
    init() {
      if (Object.keys(args?.groups).length) {
        this.groups = [...args.groups, teamObj];
      } else this.groups = [teamObj];

      if (this.hierarchyGroup && this.groups.length) {
        const hierarchyGroup = this.groups.find(
          (g) => g.encoded_id == this.hierarchyGroup.encoded_id,
        );
        if (hierarchyGroup) this.changeHierarchyGroup(hierarchyGroup);
        else this.changeHierarchyGroup(teamObj);
      } else {
        this.changeHierarchyGroup(teamObj);
      }

      if (this.aggregationTagGroup && this.aggregationOptions.length) {
        const aggregationTagGroup = this.aggregationOptions.find(
          (g) => g.encoded_id == this.aggregationTagGroup.encoded_id,
        );
        if (aggregationTagGroup)
          this.changeAggregationTagGroup(aggregationTagGroup);
        else this.changeHierarchyGroup(null);
      }

      this.$store.snapshotResultFilters.hasHierarchyGroups =
        args?.hasHierarchyGroups;
    },
    get hasHierarchyGroups() {
      return this.$store.snapshotResultFilters.hasHierarchyGroups;
    },
    get hierarchyGroup() {
      return this.$store.snapshotResultFilters.hierarchyGroup;
    },
    get aggregationTagGroup() {
      return this.$store.snapshotResultFilters.aggregationTagGroup;
    },
    toggleDropdown() {
      this.expanded = !this.expanded;
    },
    isSelected(opt) {
      if (this.aggregationTagGroup?.name) {
        return (
          this.aggregationTagGroup?.name?.toLowerCase() ==
          opt?.name?.toLowerCase()
        );
      }

      return (
        this.hierarchyGroup?.name?.toLowerCase() == opt?.name?.toLowerCase()
      );
    },
    changeHierarchyGroup(option) {
      this.$store.snapshotResultFilters.setHierarchyGroup(option);
    },
    postProcessHierarchyChange() {
      this.expanded = false;
      if (this.refetchTeamBreakdown) this.refetchTeamBreakdown();
    },
    changeAggregationTagGroup(option) {
      this.$store.snapshotResultFilters.setAggregationTagGroup(option);
    },
  };
}
